import React from "react";
import { withRouter } from "react-router-dom";
import {
	Container,
	Row,
	Col,
	Image,
	Card,
	Button,
	Form
} from "react-bootstrap";
import Header from "../elements/header";
import CategorySelectElement from "../elements/categoryselect";
import SearchBox from "../elements/searchbox";
import "./dashboard.css";
import videoicon from "../video_1541069.png";

class CustomerDashboardTemplate extends React.Component {
	render() {
		const ViewCustomerVidsButton = withRouter(({ history }) => (
			<Button
				id="view-customer-vids-button"
				className="viewall-button"
				variant="light"
				onClick={() => {
					history.push("/c/videos");
				}}
			>
				View All Videos
			</Button>
		));
		return (
			<div id="c-dash-wrapper">
				<Header
					userscope={this.props.userscope}
					useremail={this.props.useremail}
					view="customerdashboard"
				/>
				<Container fluid id="c-dash-content">
					<Row>
						<Col>
							<h1 id="customer-welcome-text">
								Welcome to our multimedia and information resource library.
							</h1>
							<h2 id="customer-subhead-text">
								Get started by selecting a search option:
							</h2>
						</Col>
					</Row>
					<Row>
						<Col>
							<Card id="customer-vids-card" style={{ width: "23rem" }}>
								<Card.Body>
									<Card.Text>
										<Image src={videoicon} />
										Share some of our amazing product videos, demonstrations,
										and cost savings programs:
									</Card.Text>
									<Form>
										<Row>
											<Col>
												<Form.Group>
													<ViewCustomerVidsButton />
												</Form.Group>
											</Col>
										</Row>
										<Row>
											<Col>
												<SearchBox section="c" />
											</Col>
										</Row>
										<Row>
											<Col>
												<Form.Group>
													<CategorySelectElement subdir={"c"} />
												</Form.Group>
											</Col>
										</Row>
									</Form>
								</Card.Body>
							</Card>
						</Col>
					</Row>
				</Container>
			</div>
		);
	}
}
export default withRouter(CustomerDashboardTemplate);
