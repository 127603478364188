import React from "react";
import { Auth } from "aws-amplify";

class UserPanelComponent extends React.Component {
	signOut = () => {
		Auth.signOut();
	};
	render() {
		return (
			<div>
				{this.props.useremail && (
					<p id="userpanel_greeting">
						Hello {this.props.useremail}
						<br />
						<a id="userpanel_signout" href="/" onClick={this.signOut}>
							{" "}
							Sign Out
						</a>
					</p>
				)}
			</div>
		);
	}
}
export default UserPanelComponent;
