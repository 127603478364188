/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createVideo = `mutation CreateVideo($input: CreateVideoInput!) {
  createVideo(input: $input) {
    id
    title
    iscustomer
    istraining
    url
    sources {
      items {
        id
        url
        type
        display
      }
      nextToken
    }
    products {
      id
      name
      url
      videos {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
      description
      linked {
        nextToken
      }
    }
    taggedKeywords {
      id
      name
      inVideos {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
    }
    keywordstring
    categories {
      id
      name
      linked {
        nextToken
      }
      hasVideos {
        nextToken
      }
    }
    inCategory {
      id
      name
      linked {
        nextToken
      }
      hasVideos {
        nextToken
      }
    }
    inPost {
      id
      type
      title
      subtitle
      shortDesc
      longDesc
      productLinks {
        nextToken
      }
      images {
        nextToken
      }
      videos {
        nextToken
      }
      contributor {
        id
        name
        level
      }
      keywordstring
      catLinks {
        nextToken
      }
      startPublishing
      endPublishing
    }
  }
}
`;
export const updateVideo = `mutation UpdateVideo($input: UpdateVideoInput!) {
  updateVideo(input: $input) {
    id
    title
    iscustomer
    istraining
    url
    sources {
      items {
        id
        url
        type
        display
      }
      nextToken
    }
    products {
      id
      name
      url
      videos {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
      description
      linked {
        nextToken
      }
    }
    taggedKeywords {
      id
      name
      inVideos {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
    }
    keywordstring
    categories {
      id
      name
      linked {
        nextToken
      }
      hasVideos {
        nextToken
      }
    }
    inCategory {
      id
      name
      linked {
        nextToken
      }
      hasVideos {
        nextToken
      }
    }
    inPost {
      id
      type
      title
      subtitle
      shortDesc
      longDesc
      productLinks {
        nextToken
      }
      images {
        nextToken
      }
      videos {
        nextToken
      }
      contributor {
        id
        name
        level
      }
      keywordstring
      catLinks {
        nextToken
      }
      startPublishing
      endPublishing
    }
  }
}
`;
export const deleteVideo = `mutation DeleteVideo($input: DeleteVideoInput!) {
  deleteVideo(input: $input) {
    id
    title
    iscustomer
    istraining
    url
    sources {
      items {
        id
        url
        type
        display
      }
      nextToken
    }
    products {
      id
      name
      url
      videos {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
      description
      linked {
        nextToken
      }
    }
    taggedKeywords {
      id
      name
      inVideos {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
    }
    keywordstring
    categories {
      id
      name
      linked {
        nextToken
      }
      hasVideos {
        nextToken
      }
    }
    inCategory {
      id
      name
      linked {
        nextToken
      }
      hasVideos {
        nextToken
      }
    }
    inPost {
      id
      type
      title
      subtitle
      shortDesc
      longDesc
      productLinks {
        nextToken
      }
      images {
        nextToken
      }
      videos {
        nextToken
      }
      contributor {
        id
        name
        level
      }
      keywordstring
      catLinks {
        nextToken
      }
      startPublishing
      endPublishing
    }
  }
}
`;
export const createImage = `mutation CreateImage($input: CreateImageInput!) {
  createImage(input: $input) {
    id
    title
    caption
    sources {
      items {
        id
        url
        type
        display
      }
      nextToken
    }
    inPost {
      id
      type
      title
      subtitle
      shortDesc
      longDesc
      productLinks {
        nextToken
      }
      images {
        nextToken
      }
      videos {
        nextToken
      }
      contributor {
        id
        name
        level
      }
      keywordstring
      catLinks {
        nextToken
      }
      startPublishing
      endPublishing
    }
  }
}
`;
export const updateImage = `mutation UpdateImage($input: UpdateImageInput!) {
  updateImage(input: $input) {
    id
    title
    caption
    sources {
      items {
        id
        url
        type
        display
      }
      nextToken
    }
    inPost {
      id
      type
      title
      subtitle
      shortDesc
      longDesc
      productLinks {
        nextToken
      }
      images {
        nextToken
      }
      videos {
        nextToken
      }
      contributor {
        id
        name
        level
      }
      keywordstring
      catLinks {
        nextToken
      }
      startPublishing
      endPublishing
    }
  }
}
`;
export const deleteImage = `mutation DeleteImage($input: DeleteImageInput!) {
  deleteImage(input: $input) {
    id
    title
    caption
    sources {
      items {
        id
        url
        type
        display
      }
      nextToken
    }
    inPost {
      id
      type
      title
      subtitle
      shortDesc
      longDesc
      productLinks {
        nextToken
      }
      images {
        nextToken
      }
      videos {
        nextToken
      }
      contributor {
        id
        name
        level
      }
      keywordstring
      catLinks {
        nextToken
      }
      startPublishing
      endPublishing
    }
  }
}
`;
export const createPost = `mutation CreatePost($input: CreatePostInput!) {
  createPost(input: $input) {
    id
    type
    title
    subtitle
    shortDesc
    longDesc
    productLinks {
      items {
        id
      }
      nextToken
    }
    images {
      items {
        id
        title
        caption
      }
      nextToken
    }
    videos {
      items {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
      nextToken
    }
    contributor {
      id
      name
      level
      posts {
        nextToken
      }
    }
    keywordstring
    catLinks {
      items {
        id
      }
      nextToken
    }
    startPublishing
    endPublishing
  }
}
`;
export const updatePost = `mutation UpdatePost($input: UpdatePostInput!) {
  updatePost(input: $input) {
    id
    type
    title
    subtitle
    shortDesc
    longDesc
    productLinks {
      items {
        id
      }
      nextToken
    }
    images {
      items {
        id
        title
        caption
      }
      nextToken
    }
    videos {
      items {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
      nextToken
    }
    contributor {
      id
      name
      level
      posts {
        nextToken
      }
    }
    keywordstring
    catLinks {
      items {
        id
      }
      nextToken
    }
    startPublishing
    endPublishing
  }
}
`;
export const deletePost = `mutation DeletePost($input: DeletePostInput!) {
  deletePost(input: $input) {
    id
    type
    title
    subtitle
    shortDesc
    longDesc
    productLinks {
      items {
        id
      }
      nextToken
    }
    images {
      items {
        id
        title
        caption
      }
      nextToken
    }
    videos {
      items {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
      nextToken
    }
    contributor {
      id
      name
      level
      posts {
        nextToken
      }
    }
    keywordstring
    catLinks {
      items {
        id
      }
      nextToken
    }
    startPublishing
    endPublishing
  }
}
`;
export const createContributor = `mutation CreateContributor($input: CreateContributorInput!) {
  createContributor(input: $input) {
    id
    name
    level
    posts {
      items {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
      nextToken
    }
  }
}
`;
export const updateContributor = `mutation UpdateContributor($input: UpdateContributorInput!) {
  updateContributor(input: $input) {
    id
    name
    level
    posts {
      items {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
      nextToken
    }
  }
}
`;
export const deleteContributor = `mutation DeleteContributor($input: DeleteContributorInput!) {
  deleteContributor(input: $input) {
    id
    name
    level
    posts {
      items {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
      nextToken
    }
  }
}
`;
export const createProduct = `mutation CreateProduct($input: CreateProductInput!) {
  createProduct(input: $input) {
    id
    name
    url
    videos {
      id
      title
      iscustomer
      istraining
      url
      sources {
        nextToken
      }
      products {
        id
        name
        url
        description
      }
      taggedKeywords {
        id
        name
      }
      keywordstring
      categories {
        id
        name
      }
      inCategory {
        id
        name
      }
      inPost {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
    }
    description
    linked {
      items {
        id
      }
      nextToken
    }
  }
}
`;
export const updateProduct = `mutation UpdateProduct($input: UpdateProductInput!) {
  updateProduct(input: $input) {
    id
    name
    url
    videos {
      id
      title
      iscustomer
      istraining
      url
      sources {
        nextToken
      }
      products {
        id
        name
        url
        description
      }
      taggedKeywords {
        id
        name
      }
      keywordstring
      categories {
        id
        name
      }
      inCategory {
        id
        name
      }
      inPost {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
    }
    description
    linked {
      items {
        id
      }
      nextToken
    }
  }
}
`;
export const deleteProduct = `mutation DeleteProduct($input: DeleteProductInput!) {
  deleteProduct(input: $input) {
    id
    name
    url
    videos {
      id
      title
      iscustomer
      istraining
      url
      sources {
        nextToken
      }
      products {
        id
        name
        url
        description
      }
      taggedKeywords {
        id
        name
      }
      keywordstring
      categories {
        id
        name
      }
      inCategory {
        id
        name
      }
      inPost {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
    }
    description
    linked {
      items {
        id
      }
      nextToken
    }
  }
}
`;
export const createCategory = `mutation CreateCategory($input: CreateCategoryInput!) {
  createCategory(input: $input) {
    id
    name
    linked {
      items {
        id
      }
      nextToken
    }
    hasVideos {
      items {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
      nextToken
    }
  }
}
`;
export const updateCategory = `mutation UpdateCategory($input: UpdateCategoryInput!) {
  updateCategory(input: $input) {
    id
    name
    linked {
      items {
        id
      }
      nextToken
    }
    hasVideos {
      items {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
      nextToken
    }
  }
}
`;
export const deleteCategory = `mutation DeleteCategory($input: DeleteCategoryInput!) {
  deleteCategory(input: $input) {
    id
    name
    linked {
      items {
        id
      }
      nextToken
    }
    hasVideos {
      items {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
      nextToken
    }
  }
}
`;
export const createKeyword = `mutation CreateKeyword($input: CreateKeywordInput!) {
  createKeyword(input: $input) {
    id
    name
    inVideos {
      id
      title
      iscustomer
      istraining
      url
      sources {
        nextToken
      }
      products {
        id
        name
        url
        description
      }
      taggedKeywords {
        id
        name
      }
      keywordstring
      categories {
        id
        name
      }
      inCategory {
        id
        name
      }
      inPost {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
    }
  }
}
`;
export const updateKeyword = `mutation UpdateKeyword($input: UpdateKeywordInput!) {
  updateKeyword(input: $input) {
    id
    name
    inVideos {
      id
      title
      iscustomer
      istraining
      url
      sources {
        nextToken
      }
      products {
        id
        name
        url
        description
      }
      taggedKeywords {
        id
        name
      }
      keywordstring
      categories {
        id
        name
      }
      inCategory {
        id
        name
      }
      inPost {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
    }
  }
}
`;
export const deleteKeyword = `mutation DeleteKeyword($input: DeleteKeywordInput!) {
  deleteKeyword(input: $input) {
    id
    name
    inVideos {
      id
      title
      iscustomer
      istraining
      url
      sources {
        nextToken
      }
      products {
        id
        name
        url
        description
      }
      taggedKeywords {
        id
        name
      }
      keywordstring
      categories {
        id
        name
      }
      inCategory {
        id
        name
      }
      inPost {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
    }
  }
}
`;
export const createSrc = `mutation CreateSrc($input: CreateSrcInput!) {
  createSrc(input: $input) {
    id
    url
    s3object {
      bucket
      region
      key
    }
    type
    display
    video {
      id
      title
      iscustomer
      istraining
      url
      sources {
        nextToken
      }
      products {
        id
        name
        url
        description
      }
      taggedKeywords {
        id
        name
      }
      keywordstring
      categories {
        id
        name
      }
      inCategory {
        id
        name
      }
      inPost {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
    }
    image {
      id
      title
      caption
      sources {
        nextToken
      }
      inPost {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
    }
  }
}
`;
export const updateSrc = `mutation UpdateSrc($input: UpdateSrcInput!) {
  updateSrc(input: $input) {
    id
    url
    s3object {
      bucket
      region
      key
    }
    type
    display
    video {
      id
      title
      iscustomer
      istraining
      url
      sources {
        nextToken
      }
      products {
        id
        name
        url
        description
      }
      taggedKeywords {
        id
        name
      }
      keywordstring
      categories {
        id
        name
      }
      inCategory {
        id
        name
      }
      inPost {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
    }
    image {
      id
      title
      caption
      sources {
        nextToken
      }
      inPost {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
    }
  }
}
`;
export const deleteSrc = `mutation DeleteSrc($input: DeleteSrcInput!) {
  deleteSrc(input: $input) {
    id
    url
    s3object {
      bucket
      region
      key
    }
    type
    display
    video {
      id
      title
      iscustomer
      istraining
      url
      sources {
        nextToken
      }
      products {
        id
        name
        url
        description
      }
      taggedKeywords {
        id
        name
      }
      keywordstring
      categories {
        id
        name
      }
      inCategory {
        id
        name
      }
      inPost {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
    }
    image {
      id
      title
      caption
      sources {
        nextToken
      }
      inPost {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
    }
  }
}
`;
export const createCatLink = `mutation CreateCatLink($input: CreateCatLinkInput!) {
  createCatLink(input: $input) {
    id
    post {
      id
      type
      title
      subtitle
      shortDesc
      longDesc
      productLinks {
        nextToken
      }
      images {
        nextToken
      }
      videos {
        nextToken
      }
      contributor {
        id
        name
        level
      }
      keywordstring
      catLinks {
        nextToken
      }
      startPublishing
      endPublishing
    }
    category {
      id
      name
      linked {
        nextToken
      }
      hasVideos {
        nextToken
      }
    }
  }
}
`;
export const updateCatLink = `mutation UpdateCatLink($input: UpdateCatLinkInput!) {
  updateCatLink(input: $input) {
    id
    post {
      id
      type
      title
      subtitle
      shortDesc
      longDesc
      productLinks {
        nextToken
      }
      images {
        nextToken
      }
      videos {
        nextToken
      }
      contributor {
        id
        name
        level
      }
      keywordstring
      catLinks {
        nextToken
      }
      startPublishing
      endPublishing
    }
    category {
      id
      name
      linked {
        nextToken
      }
      hasVideos {
        nextToken
      }
    }
  }
}
`;
export const deleteCatLink = `mutation DeleteCatLink($input: DeleteCatLinkInput!) {
  deleteCatLink(input: $input) {
    id
    post {
      id
      type
      title
      subtitle
      shortDesc
      longDesc
      productLinks {
        nextToken
      }
      images {
        nextToken
      }
      videos {
        nextToken
      }
      contributor {
        id
        name
        level
      }
      keywordstring
      catLinks {
        nextToken
      }
      startPublishing
      endPublishing
    }
    category {
      id
      name
      linked {
        nextToken
      }
      hasVideos {
        nextToken
      }
    }
  }
}
`;
export const createProductLink = `mutation CreateProductLink($input: CreateProductLinkInput!) {
  createProductLink(input: $input) {
    id
    post {
      id
      type
      title
      subtitle
      shortDesc
      longDesc
      productLinks {
        nextToken
      }
      images {
        nextToken
      }
      videos {
        nextToken
      }
      contributor {
        id
        name
        level
      }
      keywordstring
      catLinks {
        nextToken
      }
      startPublishing
      endPublishing
    }
    product {
      id
      name
      url
      videos {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
      description
      linked {
        nextToken
      }
    }
  }
}
`;
export const updateProductLink = `mutation UpdateProductLink($input: UpdateProductLinkInput!) {
  updateProductLink(input: $input) {
    id
    post {
      id
      type
      title
      subtitle
      shortDesc
      longDesc
      productLinks {
        nextToken
      }
      images {
        nextToken
      }
      videos {
        nextToken
      }
      contributor {
        id
        name
        level
      }
      keywordstring
      catLinks {
        nextToken
      }
      startPublishing
      endPublishing
    }
    product {
      id
      name
      url
      videos {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
      description
      linked {
        nextToken
      }
    }
  }
}
`;
export const deleteProductLink = `mutation DeleteProductLink($input: DeleteProductLinkInput!) {
  deleteProductLink(input: $input) {
    id
    post {
      id
      type
      title
      subtitle
      shortDesc
      longDesc
      productLinks {
        nextToken
      }
      images {
        nextToken
      }
      videos {
        nextToken
      }
      contributor {
        id
        name
        level
      }
      keywordstring
      catLinks {
        nextToken
      }
      startPublishing
      endPublishing
    }
    product {
      id
      name
      url
      videos {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
      description
      linked {
        nextToken
      }
    }
  }
}
`;
