import React from "react";
import { Modal } from "react-bootstrap";

class UlabsModal extends React.Component {
	constructor(props, context) {
		super(props, context);

		this.state = {
			show: false
		};
	}

	render() {
		let close = () => this.setState({ show: false });
		let id = this.props.id;
		function body(id) {
			switch (id) {
			case "privacy-modal":
				return (
					<Modal.Body>
						<p>
								Your privacy is important to ULabs.info, a website owned and
								operated by United Laboratories, Inc., a Delaware corporation
								(“United”). This Policy discloses the information practices for
								ULab.info, what type of information about our Web sites’ users
								is gathered and tracked and how the information is used, shared
								or otherwise processed offline. It also describes how cookies,
								web beacons or other technologies may be used. This Policy
								applies to Web sites of United affiliates that link to this
								Policy but does not apply to those Web sites that have their own
								Privacy Policy. From time to time, we may supplement this Policy
								with additional information relating to a particular interaction
								we have with you. If you have questions or complaints regarding
								this Privacy Policy, please contact us. Collection of Personal
								Information
						</p>
						<p>
								Although ULabs.info asks only for your email address and for you
								to establish a password, we do not link that information to an
								account or access to an account nor do we associate your email
								address with any other personal information through the
								ULabs.info website. However, you may choose to give United
								personal information or information relating to your business or
								organization in ways other than through ULabs.info in a variety
								of situations. For example, you may want to give United your
								name and contact information to communicate with you, to order a
								product, to process an order, to register for a service, to
								provide you with a subscription, or to do business with United
								if you are a supplier or a business partner. You may also
								provide your credit card details to buy something from United or
								may share a description of your education and work experience in
								connection with a job opening at United for which you wish to be
								considered. If you tell United that you do not want United to
								use your information to make further contact with you beyond
								fulfilling your request, we will respect your wishes. We may
								also collect information relating to your use of our Web sites
								and other offerings through the use of various technologies. For
								example, when you visit our Web sites or access our other
								offerings, we may log certain information that your browser
								sends United, such as your IP address, browser type and
								language, access time, and referring Web site addresses, and we
								may collect information about the pages or videos you view
								within our sites and other actions you take while visiting
								United. In addition, we may ask for or employ technologies that
								allow United to collect certain information about product use.
								We may also use such technologies to determine whether you have
								opened an e-mail or clicked on a link contained in an e-mail.
								Collecting information in this manner allows United to collect
								statistics about the usage and effectiveness of our Web sites,
								our products and our other offerings, personalize your
								experience and tailor our interactions with you. For details
								regarding the technologies we employ see Cookies, Web Beacons
								and Other Technologies below. From time to time, we may also
								collect information that pertains to you indirectly through
								other sources, such as list vendors. When we do so, we ask the
								vendors to confirm that the information was legally acquired by
								the third party and that we have the right to obtain it from
								them and use it. The information that we collect, either
								directly or indirectly, may be combined to help United improve
								its overall accuracy and completeness and to help United better
								tailor our interactions with you. Use of Personal Information
						</p>
						<p>
								The following describe some of the ways that we may use any
								personal information you provide:
						</p>
						<p>Fulfilling your Transaction Request.</p>
						<p>
								If you request a product or service, a callback, or specific
								marketing materials, we will use the information you provide to
								fulfill your request. To help United do this, we may share
								information with others, for instance, our business partners,
								financial institutions, shipping companies, or postal or
								government authorities, such as Customs authorities, involved in
								fulfillment. In connection with a transaction, we may also
								contact you as part of our customer satisfaction surveys or for
								market research purposes. Personalizing your Experience on our
								Web Sites. We may use information we collect about you to
								provide you with a personalized experience on our Web sites,
								such as providing you with content in which you may be
								interested and making navigation on our sites easier.
						</p>
						<p>Providing Support.</p>
						<p>
								We may use your personal information to support products or
								services you have obtained from United, such as notifying you of
								a product update or fix. We may also provide telephone customer
								support or “Live Chat” sessions on or in connection with our Web
								sites to assist you while you are navigating through our sites;
								we will use personal information you provide via these sessions
								in accordance with this Privacy Policy. In the course of
								providing support to you, we may sometimes have incidental
								access to data that you have provided to United or data that is
								located on your system. This data may contain information about
								you, your organization’s employees, customers, partners, or
								suppliers. This Privacy Policy does not apply to our access to
								or handling of this information. The conditions regarding the
								handling and processing of that data is covered by the
								applicable Terms of Use or other agreements between you and
								United, such as the Terms and Conditions relating to product
								purchases. Marketing. The information you provide to United, as
								well as the information we have collected about you indirectly,
								may be used by United for email marketing purposes. However,
								United does not sell your information to third-party marketing
								firms. You may at any time choose not to receive marketing
								materials from United by following the unsubscribe instructions
								included in each e-mail you may receive, by indicating so when
								we call you, or by contacting us directly (please refer to
								“Privacy Questions and Access” below). Some of our offerings may
								be co-branded, that is sponsored by both United and third
								parties. If you sign up for these offerings, be aware that your
								information may also be collected by and shared with those third
								parties. We encourage you to familiarize yourself with the
								privacy policies of any such third-parties to gain an
								understanding of the manner in which they will handle
								information about you. Recruiting. In connection with a job
								application or inquiry, whether advertised on our Web site or
								otherwise, you may provide United with information about
								yourself, such as a resume. We may use this information
								throughout United in order to address your inquiry or consider
								you for employment purposes. Unless you tell United not to do
								so, we may keep the information for future consideration.
								Monitoring or Recording of Calls, Chats and Other Interactions.
								Certain online transactions may involve you calling us or us
								calling you. They may also involve online chats. Please be aware
								that United may monitor such interactions for staff training or
								quality assurance purposes or to retain evidence of a particular
								transaction or interaction.
						</p>
						<p>
								Mobile Applications and Use of Information in the Social
								Computing Environment.
						</p>
						<p>
								United may make available mobile applications for download from
								various mobile application marketplaces. United also may provide
								social computing tools, individually and as part of a
								third-party social media platform, on some of its Web sites to
								enable online sharing and collaboration among United’s
								employees, representatives, customers and website visitors.
								These include forums, wikis, blogs and other social media
								platforms. When downloading and using mobile applications or
								registering to use social computing tools, you may be asked to
								provide certain personal information. Registration information
								will be subject to and protected in accordance with this Privacy
								Policy, except for the information that is automatically made
								available to other participants as part of your social media
								profile. These applications and tools may also include
								supplemental privacy policies with specific information about
								collection and handling practices. Please read those
								supplemental policies to understand what the tools and
								applications may do. Any other content you post, such as
								pictures, information, opinions, or any other type of personal
								information that you make available to other participants on
								these social platforms or applications, is not subject to this
								Privacy Policy. Rather, such content is subject to the Terms of
								Use of those applications or platforms, and any additional
								guidelines and privacy information provided in relation to their
								use, as well as the process by which you can remove your content
								from such tools or get help to do so. Please refer to them to
								better understand yours, United&apos;s, and other parties&apos;
								rights and obligations with regard to such content. You should
								be aware that the content you post on any such social computing
								platforms may be made broadly available to others inside and
								outside United. Protect the Rights and Property of us and
								Others. We may also use or share your information to protect the
								rights or property of United, our business partners, suppliers,
								clients, or others when we have reasonable grounds to believe
								that such rights or property have been or could be affected. In
								addition, we reserve the right to disclose your personal
								information as required by law and when we believe that
								disclosure is necessary to protect our rights, or the rights of
								others, or to comply with a judicial proceeding, court order, or
								legal process served on our Web sites. Information for Business
								Partners. If you are a United employee, distributor or
								representative or represent a United customer, supplier or other
								business partner, you may visit our Web sites intended
								specifically for United employees, customers and suppliers. We
								may use information provided on such sites to administer and
								develop our business relationship with you, the business partner
								you represent, and United’s business generally. For instance,
								this may involve using your information to send you details of
								our business partner programs. It may also include sharing
								certain information with other business partners (subject to any
								confidentiality obligations that may exist), or United customers
								or prospects. In connection with a particular transaction or
								program, we may also contact you as part of customer
								satisfaction surveys or for market research purposes.
								Information for Suppliers. If you represent a United supplier,
								you may visit our Web sites intended specifically for use by
								United suppliers. We may use the information provided on that
								site in connection with entering into or performing a
								transaction with you. For example, this may include sharing
								information with other parts of United, United&apos;s business
								partners, customers, shipping companies, financial institutions
								and postal or government authorities involved in fulfillment. It
								may also be used to administer and develop our relationship with
								you, the supplier you represent, and other United suppliers
								generally. Sharing of Personal Information and International
								Transfers. United has business processes, management structures
								and technical systems that cross national borders. As such, we
								may share information about you within United and transfer it to
								countries in the world where we do business in connection with
								the uses identified above and in accordance with this Privacy
								Policy. In some cases, United may use suppliers located in
								various countries to supply raw material and products and to
								collect, use, analyze, and otherwise process information on its
								behalf. It is United&apos;s practice to require such suppliers
								to handle information in a manner consistent with United&apos;s
								policies. Circumstances may arise where, whether for strategic
								or other business reasons, United decides to sell, buy, merge or
								otherwise reorganize businesses in some countries. Such a
								transaction may involve the disclosure of personal information
								to prospective or actual purchasers, or the receipt of such
								information from sellers. It is United’s practice to seek
								appropriate protection for information in these types of
								transactions. When transferring your information outside of the
								country of collection for the purposes identified above, United
								will comply with applicable law. For data originating from a
								European Union member state, United will use a variety of data
								transfer mechanisms (including standard contractual clauses) for
								this purpose.
						</p>
						<p>
								Please be aware that in certain circumstances, personal
								information may be subject to disclosure to government agencies
								pursuant to judicial proceeding, court order, or legal process.
								We may also share your information to protect the rights or
								property of United, our employees, customers and business
								partners and others when we have reasonable grounds to believe
								that such rights or property have been or could be affected.
								Retention
						</p>
						<p>
								United will retain your registration information for as long as
								your account is active or as needed to provide you products or
								services. If you wish to request that we no longer use your
								registration information to provide you product or services
								contact us at websupport@unitedlabsinc.com. We will retain and
								use your registration information as necessary to comply with
								our legal obligations, resolve disputes, and enforce our
								agreements
						</p>
						<p>Cookies, Web Beacons and Other Technologies</p>
						<p>
								As mentioned above, we collect information from your visits to
								our Web sites to help us gather statistics about usage and
								effectiveness, personalize your experience, and tailor our
								interactions with you. We do so through the use of various
								technologies, including scripts, tags, Local Shared Objects
								(Flash cookies), Local Storage (HTML5) beacons, and one called
								&ldquo;cookies&rdquo;. A cookie is a piece of data that a Web
								site can send to your browser, which may then be stored on your
								computer as a tag that identifies your computer. While cookies
								are often only used to measure Web site usage (such as number of
								visitors and duration of visit) and effectiveness (such as
								topics visitors are most interested in) and to allow for ease of
								navigation or use and, as such, are not associated with any
								personal information, they are also used at times to personalize
								a known visitor&apos;s experience to a Web site by being
								associated with profile information or user preferences. Over
								time this information provides valuable insight to help improve
								the user experience. Cookies are typically categorized as
								&ldquo;session&rdquo cookies or &ldquo;persistent&rdquo;
								cookies. Session cookies help you navigate through the Web site
								efficiently, keeping track of your progression from page to page
								so that you are not asked for information you have already
								provided during the current visit. Session cookies are stored in
								temporary memory and erased when the web browser is closed.
								Persistent cookies, on the other hand, store user preferences
								for current and successive visits. They are written on your
								device&apos;s hard disk and are still valid when you restart
								your browser. We use persistent cookies, for example, to record
								your choice of language and country location. You can generally
								express your privacy preferences regarding the use of most
								cookies and similar technologies though your web browser. Look
								under the heading &ldquo;Tools&rdquo; (or similar heading) in
								your particular browser for information about controlling
								cookies. You can set your browser in most instances to notify
								you before you receive a cookie, giving you the chance to decide
								whether to accept it or not. You can also generally set your
								browser to turn off cookies. If you block, turn off or otherwise
								reject our cookies, some Web pages may not display properly or
								you will not be able, for instance, to add items to your
								shopping cart, proceed to checkout, or use Web site services
								that require you to sign in. Some of our Web sites may also use
								Web beacon or other technologies to better tailor those sites to
								provide better customer service. These technologies may be in
								use on a number of pages across United&apos;s Web sites. When a
								visitor accesses these pages, a non-identifiable notice of that
								visit is generated which may be processed by United. These Web
								beacons usually work in conjunction with cookies. If you
								don&apos;t want your cookie information to be associated with
								your visits to these pages or use of these products, you can set
								your browser to turn off cookies or turn off cookies in the
								product itself, respectively. If you turn off cookies, Web
								beacon and other technologies will still detect visits to these
								pages; however, they will not be associated with information
								otherwise stored in cookies. We may use Local Shared Objects,
								such as Flash cookies, and Local Storage, such as HTML5, to
								store content information and preferences. Third parties with
								whom we may partner to provide certain features on our website
								or to display United’s advertising on other web sites based upon
								your web browsing activity may also use Flash cookies or HTML5
								to collect and store information. Various browsers may offer
								their own management tools for removing HTML5. We may also
								include Web beacons in marketing e-mail messages or our
								newsletters in order to determine whether messages have been
								opened and links contained within clicked on. Some of our
								business partners may set Web beacons and cookies on our site.
								In addition third-party social media buttons may log certain
								information such as your IP address, browser type and language,
								access time, and referring Web site addresses, and, if you are
								logged in to those social media sites, they may also link such
								collected information with your profile information on that
								site. We do not control these third-party tracking technologies.
						</p>
						<p>Online Advertising</p>
						<p>
								United does not deliver third party online advertisements on our
								Web sites but we may advertise our products and services on
								others&apos; Web sites. Please familiarize yourself with those
								Web site operators&apos; or network advertisers&apos; privacy
								policies to understand their practices relating to advertising,
								including what type of information they may collect about your
								Internet usage.
						</p>
						<p>Links to Non-United Web Sites and Third-Party Applications</p>
						<p>
								To allow you to interact with other Web sites on which you may
								have accounts (such as Facebook and other social media sites) or
								join communities on such sites, we may provide links or embed
								third-party applications that allow you to login, post content
								or join communities from our Web sites. We may also provide you
								with general links to non-United Web sites.
						</p>
						<p>
								Your use of these links and applications is subject to the third
								parties’ privacy policies, and you should become familiar with
								the privacy policies of any such third-party sites before using
								the links or applications. United is not responsible for the
								privacy practices or the content of those other Web sites.
						</p>
						<p>Privacy Questions</p>
						<p>
								Please direct any questions about this Privacy Policy to United
								at websupport@unitedlabsinc.com.
						</p>
					</Modal.Body>
				);
			case "terms-modal":
				return (
					<Modal.Body>
						<p>
								Your use of ULabs.info is subject to these Terms of Use, which
								describe your rights and responsibilities and those of
								ULabs.info, including the rights you grant to United
								Laboratories, Inc. (“United”), and restrictions on how you can
								use this web site.
						</p>
						<p>
								This web site or mobile application (&ldquo;ULabs.info&rdquo; or
								“Site”) is owned and operated by United, on behalf of itself and
								for its subsidiaries and affiliates (collectively,
								&ldquo;United,&rdquo; &ldquo;We&rdquo; or &ldquo;Us&rdquo;). By
								accessing, signing in, using or registering for the Site, all
								visitors and users (&ldquo;Users&rdquo;) agree to be bound by
								these Terms of Use (the &ldquo;Terms&rdquo;). United reserves
								the right to modify the Terms at any time without prior notice
								and United recommends that you read these Terms carefully each
								time you use the ULabs.info site. If you are unwilling to be
								bound by these Terms, you should not access, use, register for,
								or view videos or collateral from the ULabs.info Site. Except as
								otherwise noted, these Terms apply to the entire network of
								United-owned Sites and control your use of those Sites.
						</p>
						<p>Intended Use</p>
						<p>
								The ULabs.info is targeted toward and intended for use by
								customers, potential customers, employees, representatives,
								suppliers and others who are interested in the products and
								services of United. By using the ULabs.info Site, you represent
								and warrant that you have such interest and agree to use the
								information contained on the ULabs.info Site for those purposes
								only. United products are intended for business, commercial,
								governmental users and certain products may be restricted for
								sale to persons qualified for their use (depending on the state
								or jurisdiction of residence).
						</p>
						<p>Privacy</p>
						<p>
								United believes strongly in the protection of the privacy of
								Users and our customers. Our data collection and use practices
								are set forth in our Privacy Policy, which we encourage you to
								review.
						</p>
						<p>Changes to the ULabs.info Site</p>
						<p>
								You agree and understand that the ULabs.info Site, including any
								and all features available via the ULabs.info Site and any User
								Content (as defined below), may be modified by us, in our sole
								discretion, at any time without prior notice. Unless expressly
								stated otherwise, any new features, new services, enhancements
								or modifications to the ULabs.info Site implemented after your
								initial access to the ULabs.info Site shall be subject to these
								Terms. We do not actively monitor, and undertake no obligation
								to monitor or modify, any reviews, information, content, data,
								text, links to third party websites, User profile information,
								sounds, photographs, graphics, video, messages or other
								materials uploaded or made available via the ULabs.info Site by
								or on behalf of any User (all such items provided by or on
								behalf of Users, collectively, &ldquo;User Content&rdquo;).
								Nonetheless, we reserve the right to investigate and take
								appropriate action, including legal action, in our sole
								discretion, against anyone who violates these Terms, including
								without limitation, by removing any User Content posted in
								violation of these Terms, terminating the registration of such
								violators or blocking such violators&apos; use of the ULabs.info
								Site.
						</p>
						<p>Registration</p>
						<p>
								In order to access some features of the ULabs.info Site, you may
								be required to register by providing an email address and
								selecting a password and / or user name (&ldquo;User ID&rdquo;).
								To register, use the registration form available by clicking the
								&ldquo;register&rdquo; link on the ULabs.info Site. If you
								register, you agree to provide us with accurate and complete
								registration information, and to inform us immediately of any
								updates or other changes to such information. For example, you
								may not: (i) enter, select or use a false name or an email
								address owned or controlled by another person with the intent to
								impersonate that person, or (ii) use as a User ID a name subject
								to any rights of a person other than yourself without
								appropriate authorization. Failure to comply with the terms of
								this paragraph shall constitute a material breach of these
								Terms, which may result in immediate termination of your
								account. In addition, we reserve the right to refuse
								registration of, or cancel, a User ID in our discretion.
						</p>
						<p>Security</p>
						<p>
								You are responsible for maintaining the confidentiality of your
								password and you are fully responsible for all activities that
								occur under your User ID and password, whether or not you
								authorize such activities. Any User ID and password for your
								access to the ULabs.info Site shall be for your personal,
								non-commercial use only. You agree to (a) immediately notify us
								of any unauthorized use of your User ID or password of which you
								become aware, and (b) ensure that you exit from your account at
								the end of each session.
						</p>
						<p>Use of the ULabs.info Site/License/Services</p>
						<p>
								ULabs.info grants you a limited license to make personal use of
								the ULabs.info Site. This license grant does not include: (a)
								any resale or commercial use of the ULabs.info Site or content
								therein; (b) the collection and use of any product listings or
								descriptions; (c) making derivative uses of the ULabs.info Site
								and its contents; or (d) use of any data mining, robots, or
								similar data gathering and extraction methods on the ULabs.info
								Site. Except as noted above, Users of the ULabs.info Site are
								not conveyed any right or license by implication, estoppel, or
								otherwise in or under any patent, trademark, copyright, or
								proprietary right of ULabs.info or any third party.
						</p>
						<p>
								You may not use, frame or utilize framing techniques to enclose
								any ULabs.info trademark, logo, content or other proprietary
								information (including the images found at this Site, the
								content of any text or the layout/design of any page or form
								contained on a page) without United’s express written consent.
								Further, you may not use any meta tags or any other
								&ldquo;hidden text&rdquo; utilizing a United tradename,
								trademark, or product name without United’s express written
								consent.
						</p>
						<p>
								Any unauthorized use of the ULabs.info Site will terminate the
								permission or license granted by these Terms and may violate
								copyright laws, trademark laws (including trade dress), and
								communications regulations and statutes. All violators will be
								prosecuted to the fullest extent of the law.
						</p>
						<p>
								Permitted uses of the ULabs.info Site include soliciting
								opinions, ideas and other input from Users; sharing ideas and
								opinions with United, searching the ULabs.info Site for United
								business or product information, and purchasing goods or
								services from the ULabs.info Site for personal use, not for
								resale. You may also invite other people to visit the ULabs.info
								Site. You acknowledge and agree that we do not control any User
								Content posted to the ULabs.info Site, or any links to other
								websites, including the content of any messages posted by Users,
								and that we do not guarantee the accuracy, integrity or quality
								of any User Content. You further understand and agree that we do
								not necessarily endorse, support, sanction, encourage, verify,
								or agree with the comments, opinions, or statements or other
								User Content posted on or otherwise displayed on or transmitted
								via the ULabs.info Site. All User Content, including advice and
								opinions posted by Users, comprises the views and responsibility
								of those who post such User Content, and does not necessarily
								represent the views of ULabs.info. You understand that, by using
								the ULabs.info Site, you may be exposed to User Content that is
								offensive, indecent or objectionable.
						</p>
						<p>Restrictions on Rights to Use</p>
						<p>
								Without limiting the generality of any other provisions of these
								Terms, you agree you shall not (and you agree not to allow any
								other individual or entity using your User ID to):
						</p>
						<p>
								download, modify, reproduce, adapt, translate, reverse engineer,
								create derivative works based upon, publicly display, sell,
								rent, license, or in any way commercially exploit any portion of
								the ULabs.info Site, or any video, audio or written information
								contained on the ULabs.info Site, except and to the extent
								expressly permitted under these Terms;
						</p>
						<p>
								remove any copyright, trademark or other proprietary rights
								notice contained in or on the ULabs.info Site;
						</p>
						<p>
								use any robot, spider, site search/retrieval application, or
								other device to retrieve or index any portion of the ULabs.info
								Site;
						</p>
						<p>
								collect any information about other Users (including usernames
								and/or email addresses) for any purpose other than to solicit
								and/or share reviews with other Users;
						</p>
						<p>
								reformat or frame any portion of any Web pages that are part of
								the ULabs.info Site;
						</p>
						<p>
								create user accounts by automated means or under false or
								fraudulent pretenses;
						</p>
						<p>
								create or transmit to other Users unsolicited electronic
								communications, such as &ldquo;spam,&rdquo; or otherwise
								interfere with other Users&apos; enjoyment of the ULabs.info
								Site;
						</p>
						<p>
								submit to the ULabs.info Site any content that falsely states or
								implies that such content is sponsored or endorsed by us;
						</p>
						<p>
								transmit or upload to the ULabs.info Site any item containing or
								embodying any virus, worm, defect, Trojan horse, software bomb
								or other feature designed to damage or degrade in any manner the
								performance of the ULabs.info Site, any other Web site, or any
								computer or other device or system, or the enjoyment of the
								ULabs.info Site by any User;
						</p>
						<p>
								use the ULabs.info Site to violate the security of or gain
								unauthorized access to any computer or computer network or other
								device or system (including unauthorized attempts to discover
								passwords or security encryption codes);
						</p>
						<p>
								submit to the ULabs.info Site any content that is unlawful or
								facilitates, constitutes, promotes or encourages illegal
								activity; or otherwise use the ULabs.info Site to transfer or
								store illegal material, including any material deemed
								threatening or obscene;
						</p>
						<p>
								copy or store any User Content offered on the ULabs.info Site
								other than for your personal, non-commercial use;
						</p>
						<p>
								take any action that imposes, or may impose, in our sole
								discretion, an unreasonable or disproportionately large data or
								traffic load on the ULabs.info Site or the IT infrastructure
								used to operate and make the ULabs.info Site available;
						</p>
						<p>
								use the ULabs.info Site and/ or any User Content, intentionally
								or unintentionally, to violate any applicable local, state,
								federal or international law; or
						</p>
						<p>
								collect or store personal data about other Users in connection
								with the prohibited activities described in this paragraph.
						</p>
						<p>Ownership</p>
						<p>
								As between you and us, the ULabs.info Site, including all
								photographs, images, text, graphics, icons, audio clips,
								software and other aspects thereof, all improvements or
								modifications thereof, all derivative works based thereon, and
								the collection, arrangement, and assembly of the ULabs.info
								Site, including all copyrights, trademarks, and other
								intellectual property or proprietary rights in the foregoing,
								are owned by United or our licensors. As between you and us,
								subject to any licenses and rights expressly granted herein, any
								User Content posted by you is owned by you. Nothing contained in
								these Terms shall be deemed to grant to you or any other User
								any rights, title or interest in or to any copyright, trademark
								or other proprietary right of ours or any of our licensors.
						</p>
						<p>
								Any trademarks, service marks, and other marks and indicators of
								source or origin that are displayed on the ULabs.info Site are
								the proprietary property of United or their respective owners,
								as applicable. None of such marks may be used in connection with
								any other product or service in a manner that is likely to cause
								confusion among consumers, or to disparage or discredit the
								owner of such mark or its affiliates. Any trademarks of third
								parties that appear on the ULabs.info Site are the property of
								their respective owners, who may or may not be affiliated with,
								connected to, or sponsored by us. You may not use, copy, modify
								or display any of the trademarks, service marks, names or logos
								appearing on the ULabs.info Site without the express written
								permission of the trademark owner.
						</p>
						<p>
								Nothing in these Terms shall be deemed to grant to you or any
								other User any license or right in or to any patent, copyright,
								trademark, trade secret or other proprietary right of United.
						</p>
						<p>Changes to Terms</p>
						<p>
								We reserve the right to make changes at any time to these Terms.
								Any modifications to these Terms will be effective upon posting.
								You agree to review the Terms periodically so that you are aware
								of any modifications. Your continued use of the ULabs.info Site
								after any modifications indicates your acceptance of the
								modified Terms.
						</p>
						<p>Applicable law</p>
						<p>
								By visiting the ULabs.info Site, you agree that the laws of the
								State of Illinois, without regard to principles of conflict of
								laws, will govern these Terms and any dispute of any sort that
								might arise between you and United. In any dispute arising from
								these Terms or from your use of ULabs.info or any other United
								Site, you agree that any such dispute will be first submitted to
								private mediation and you agree to participate in one mediation
								conference with a Mediator from Business Mediation Network, LLC
								(“BMN”) under the current BMN Mediation Rules published at , or
								with another mutually acceptable Mediator. Unless otherwise
								mutually agreed, any such Mediation Conference shall be
								conducted in Kane County, Illinois at a reasonable time and
								place.
						</p>
						<p>Miscellaneous</p>
						<p>
								No agency, partnership, joint venture, or employment
								relationship is created as a result of these Terms, and you do
								not have any authority of any kind to bind United in any respect
								whatsoever. These Terms, which shall be deemed accepted by you
								upon your use of the ULabs.info Site, constitute the entire
								agreement among you and ULabs.info regarding use of the
								ULabs.info Site. United&apos;s failure to exercise or enforce
								any right or provision of these Terms shall not constitute a
								waiver of the enforcement of such right or provision. If any
								provision of these Terms is deemed to be illegal or
								unenforceable, the remainder of the Terms shall be unaffected
								and shall continue to be fully valid, binding and enforceable.
								These Terms are not assignable, transferable or sublicensable by
								you, except with our prior written consent. The headings in
								these Terms are for convenience only and have no legal or
								contractual effect. These Terms include and incorporate by
								reference the Privacy Policy for the ULabs.info Site and any
								notices regarding the ULabs.info Site.
						</p>
						<p>Contact and Violations</p>
						<p>
								Please contact us with any questions regarding these Terms.
								Please report any violations of the Terms to
								websupport@unitedlabsinc.com.
						</p>
					</Modal.Body>
				);
			default:
				return "Error: Modal text not defined.";
			}
		}

		return (
			<div style={{ display: "inline", cursor: "pointer" }}>
				<span onClick={() => this.setState({ show: true })}>
					{this.props.label}
				</span>
				<Modal
					show={this.state.show}
					onHide={close}
					aria-labelledby={this.props.id}
					dialogClassName="ulabsmodal-dialog"
				>
					<Modal.Header closeButton>
						<Modal.Title id={this.props.id}>{this.props.title}</Modal.Title>
					</Modal.Header>

					{body(id)}
				</Modal>
			</div>
		);
	}
}
export default UlabsModal;
