import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelopeOpenText } from "@fortawesome/free-solid-svg-icons";
import { faFacebookF } from "@fortawesome/fontawesome-free-brands";
import { faTwitter } from "@fortawesome/fontawesome-free-brands";
import { faLinkedinIn } from "@fortawesome/fontawesome-free-brands";
import UlabsModal from "../components/ulabsmodal";
const Footer = props => {
	props;
	return (
		<div>
			<Container fluid id="footer" className="d-none d-sm-block">
				<Row className="justify-content-md-center">
					<Col className="footer-tag-line">
						Green Products That Work, Since 1964
					</Col>
				</Row>
				<Row className="justify-content-md-center">
					<Col className="footer-address-line">
						United Laboratories, Inc. 320 37TH Avenue, St. Charles, IL 60174
					</Col>
				</Row>
				<Row className="justify-content-md-center">
					<Col className="footer-website">
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://www.unitedlabsinc.com"
						>
							unitedlabsinc.com
						</a>
					</Col>
					<Col className="footer-phone">
						PH: <a href="tel:+01-800-323-2594">800-323-2594</a>
					</Col>
				</Row>
				<hr />
				<Row className="justify-content-md-center">
					<Col className="footer-social">
						Folow Us:
						<a
							href="https://www.facebook.com/unitedlabsinc"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FontAwesomeIcon icon={faFacebookF} />
						</a>
						<a
							href="https://twitter.com/unitedlabsinc"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FontAwesomeIcon icon={faTwitter} />
						</a>
						<a
							href="https://www.linkedin.com/company/unitedlabsinc/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<FontAwesomeIcon icon={faLinkedinIn} />
						</a>
					</Col>
					<Col className="footer-email">
						<FontAwesomeIcon icon={faEnvelopeOpenText} />
						<a href="mailto:admin@ulabs.info"> Email The HelpDesk</a>
					</Col>
				</Row>
				<Row className="justify-content-md-center">
					<Col className="footer-copyright" sm={12}>
						&copy; {new Date().getFullYear()} Copyright United Laboratories,
						Inc. All Rights Reserved |
						<UlabsModal
							id="terms-modal"
							label="Terms of Use"
							title="Terms of Use"
						/>
						|
						<UlabsModal
							id="privacy-modal"
							label="Privacy Policy"
							title="Privacy Policy"
						/>
					</Col>
				</Row>
			</Container>
			<Container fluid id="footer-mobile" className="d-block d-sm-none">
				<Row className="justify-content-md-center">
					<Col className="footer-tag-line">
						Green Products That Work, Since 1964
					</Col>
				</Row>
				<Row className="justify-content-md-center">
					<Col className="footer-address-line">
						United Laboratories, Inc. 320 37TH Avenue, St. Charles, IL 60174
					</Col>
				</Row>
				<Row className="justify-content-md-center">
					<ul>
						<li className="footer-website">
							<a
								target="_blank"
								rel="noopener noreferrer"
								href="https://www.unitedlabsinc.com"
							>
								unitedlabsinc.com
							</a>
						</li>
						<li className="footer-phone">
							PH: <a href="tel:+01-800-323-2594">800-323-2594</a>
						</li>
						<li className="footer-email">
							<a href="mailto:admin@ulabs.info">Email The Helpdesk</a>
						</li>
					</ul>
				</Row>
				<Row className="justify-content-md-center">
					<Col className="footer-copyright" xs={12}>
						&copy; {new Date().getFullYear()} Copyright United Laboratories,
						Inc. All Rights Reserved
					</Col>
					<Col className="footer-tou">
						<Link to="/tos/txt"> Terms of Use </Link>
					</Col>
					<Col className="footer-privacy-policy">
						<button variant="link">Privacy Policy</button>
					</Col>
				</Row>
			</Container>
		</div>
	);
};

export default Footer;
