import React from "react";
import { withRouter } from "react-router-dom";

import matchSorter from "match-sorter";
import { API, graphqlOperation } from "aws-amplify";

import CreateVidComponent from "../../components/createvid";
import VidsTable from "../../components/vidstable";

import "../../components/vidstable.css";

const listVideosQuery = `query ListVideos(
  $filter: ModelVideoFilterInput
  $limit: Int
  $nextToken: String
) {
  listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      iscustomer
      istraining
      url
      products {
        id
        name
        url
        description
      }
      taggedKeywords {
        id
        name
      }
      keywordstring
      categories {
        id
        name
      }
      inCategory {
        id
        name
      }
      inPost {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
    }
    nextToken
  }
}
`;
class VideosTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			listVideos: {
				items: [
					{
						id: "",
						title: "",
						url: "",
						iscustomer: "",
						istraining: "",
						inCategory: "",
						keywordstring: "",
						products: [
							{
								id: ""
							}
						],
						categories: []
					}
				]
			},
			tableloading: true,
			section: ""
		};
	}
	componentDidMount() {
		this.getVideos();
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.section !== prevState.section) {
			return { section: nextProps.section };
		} else return null;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.section !== this.props.section) {
			this.getVideos();
		}
	}

	getVideos = async () => {
		this.setState({ tableloading: true });
		let filter = {
			iscustomer: {
				eq: true
			}
		};
		if (this.state.section === "training") {
			filter = { istraining: { eq: true } };
		}
		const allVids = await API.graphql(
			graphqlOperation(listVideosQuery, { limit: 500, filter: filter })
		);
		this.setState(allVids.data);
		this.setState({ tableloading: false });
	};

	render() {
		const tabledata = this.state.listVideos.items;
		tabledata.map(
			item =>
				(item.productslist = item.products
					? item.products.map(product => (
						<span className="productnum_span" key={product.id.toString()}>
							{product.id.toString()}
						</span>
					  ))
					: "")
		);

		const tablecolumns = [
			{
				Header: "Title",
				accessor: "title", // String-based value accessors!,
				FilterMethod: (filter, rows) =>
					matchSorter(rows, filter.value, { keys: ["title", "id"] }),
				filterAll: true
			},
			{
				Header: "Products Featured",
				accessor: "productslist"
			},
			{
				id: "inCategory",
				Header: "Category",
				accessor: "inCategory.id",
				filterAll: true,
				headerStyle: { display: "none" },
				style: { display: "none" }
			},
			{
				id: "categories",
				Header: "Catergories",
				accessor: function accessor(d) {
					if (d.categories) {
						return d.categories.map(function(category) {
							return category["id"] + " ";
						});
					} else {
						return " ";
					}
				},
				headerStyle: { display: "none" },
				style: { display: "none" },
				filterAll: true
			},
			{
				Header: "id",
				accessor: "id",
				filterAll: true,
				headerStyle: { display: "none" },
				style: { display: "none" }
			},
			{
				id: "keywordstring",
				Header: "Keywordstring",
				accessor: "keywordstring",
				filterAll: true,
				headerStyle: { display: "none" },
				style: { display: "none" }
			}
		];

		return (
			<div id="videos-template-wrapper">
				<section id="maincontent">
					{this.props.section === "admin" && <CreateVidComponent />}
					<VidsTable
						data={tabledata}
						columns={tablecolumns}
						searchQuery={this.props.searchQuery}
						category={this.props.match.params.cat}
						loading={this.state.tableloading}
						section={this.props.section}
					/>
				</section>
			</div>
		);
	}
}
export default withRouter(VideosTemplate);
