import React from "react";
import { withRouter } from "react-router-dom";
import matchSorter from "match-sorter";
import ReactTable from "react-table";
import CreatePostComponent from "../components/createpost";
import { Container, Row, Col } from "react-bootstrap";
import "./vidstable.css";

class PostsTable extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			addPostModalVisible: false
		};
	}
	render() {
		return (
			<Container
				fluid
				className="wht-fluid-container"
				id="poststable_container"
			>
				<Container>
					<Row>
						<Col>
							<Row id="above_table_row">
								<Col id="add_contribution_col" sm={4}>
									<CreatePostComponent
										userscope={this.props.userscope}
										section={this.props.section}
									/>
								</Col>
								<Col id="filterby_notice_col" sm={3}>
									{this.props.category ? "Filered By:  " : ""}{" "}
									{this.props.category}
								</Col>
								<Col id="badges_key_col">
									<img
										id="badges_key_img"
										src={process.env.PUBLIC_URL + "/img/badges_key.png"}
										alt="User Badges Key"
									/>
								</Col>
							</Row>
							<Row>
								<Col>
									<ReactTable
										className="-striped -highlight"
										data={this.props.data}
										columns={this.props.columns}
										loading={this.props.loading}
										loadingText={
											<div
												className="table-loading-div spinner-border text-success"
												role="status"
											>
												<span className="sr-only">Loading...</span>
											</div>
										}
										noDataText="No posts found to match your search"
										showPagination={true}
										showPaginationTop={true}
										showPaginationBottom={true}
										defaultFilterMethod={(filter, rows) =>
											matchSorter(rows, filter.value, {
												keys: ["title", "categories", "keywordstring"]
											})
										}
										filterAll
										//sorted={[{ // the sorting model for the table
										// id: 'title',
										// asc: true
										// }
										// ]}
										filtered={[
											{
												// the current filters model
												id: "title",
												value: this.props.searchQuery
											},
											{
												id: "categories",
												value: this.props.category
											},
											{
												id: "keywordstring",
												value: this.props.keywordstring
											}
										]}
										getTdProps={(state, rowInfo) => {
											if (rowInfo) {
												return {
													onClick: () =>
														this.props.history.push(
															"/" +
																this.props.section +
																"/post/" +
																rowInfo.original.id
														)
												};
											} else {
												return {
													onClick: () =>
														this.props.history.push(
															"/" + this.props.section + "/fromthefield/"
														)
												};
											}
										}}
									/>
								</Col>
							</Row>
						</Col>
					</Row>
				</Container>
			</Container>
		);
	}
}

export default withRouter(PostsTable);
