import React from "react";
import { API, graphqlOperation } from "aws-amplify";

import {
	createVideo as CreateVideoMutation,
	createSrc as CreateSrcMutation
} from "../graphql/mutations";

import Collapse from "react-bootstrap/Collapse";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";

import "./createvid.css";
class CreateVidComponent extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			collapseVisible: false,
			newid: "",
			title: "",
			url: "",
			type: "",
			iscustomer: false,
			istraining: false,
			keywordstring: "",
			catid: ""
		};
	}

	collapseChange = e => {
		e;
		this.setState({
			collapseVisible: !this.state.collapseVisible
		});
	};

	onChange = e => {
		let value =
			e.target.type === "checkbox" ? e.target.checked : e.target.value;
		this.setState({
			[e.target.name]: value
		});
	};

	createVideo = async () => {
		if (this.state.title === "" || this.state.url === "") return;
		try {
			const video = {
				input: {
					id: this.state.newid,
					title: this.state.title,
					url: this.state.url,
					iscustomer: this.state.iscustomer,
					istraining: this.state.istraining,
					videoInCategoryId: this.state.catid,
					keywordstring: this.state.keywordstring
				}
			};
			await API.graphql(graphqlOperation(CreateVideoMutation, video));
			const src = {
				input: {
					src: this.state.url,
					srcVideoId: this.state.newid,
					type: this.state.type
				}
			};
			await API.graphql(graphqlOperation(CreateSrcMutation, src));
			this.setState({ title: "", url: "" });
			console.log("video successfully created");
		} catch (err) {
			console.log("error creating video");
		}
	};

	render() {
		return (
			<Container className="CreateVidComponent">
				<Button
					onClick={this.collapseChange}
					aria-controls="collapse-content"
					aria-expanded={this.state.collapseVisible}
				>
					Toggle New Video Form
				</Button>
				<Collapse in={this.state.collapseVisible}>
					<div id="collapse-content">
						<br />
						<Form>
							<Form.Group as={Row} controlId="newid">
								<Form.Label column sm={2}>
									Unique identifier:
								</Form.Label>
								<Col>
									<Form.Control
										type="text"
										value={this.state.newid}
										onChange={this.onChange}
										name="newid"
									/>
									<Form.Text className="text-muted">
										This will be used as the url suffix
									</Form.Text>
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId="title">
								<Form.Label column sm={2}>
									Title:
								</Form.Label>
								<Col>
									<Form.Control
										type="text"
										value={this.state.title}
										onChange={this.onChange}
										name="title"
									/>
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId="url">
								<Form.Label column sm={2}>
									URL:
								</Form.Label>
								<Col>
									<Form.Control
										type="text"
										value={this.state.url}
										onChange={this.onChange}
										name="url"
									/>
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId="type">
								<Form.Label column sm={2}>
									URL Type:
								</Form.Label>
								<Col>
									<Form.Control
										type="text"
										value={this.state.type}
										onChange={this.onChange}
										name="type"
									/>
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId="catid">
								<Form.Label column sm={2}>
									Category Id:
								</Form.Label>
								<Col>
									<Form.Control
										type="text"
										value={this.state.catid}
										onChange={this.onChange}
										name="catid"
									/>
								</Col>
							</Form.Group>
							<Form.Group as={Row} controlId="keywordstring">
								<Form.Label column sm={2}>
									keywordstring:
								</Form.Label>
								<Col>
									<Form.Control
										type="text"
										value={this.state.keywordstring}
										onChange={this.onChange}
										name="keywordstring"
									/>
								</Col>
							</Form.Group>
							<Form.Row>
								<Form.Group as={Col} controlId="iscustomer">
									<Form.Check
										type="checkbox"
										label="Customer Facing"
										checked={this.state.iscustomer}
										onChange={this.onChange}
										name="iscustomer"
									/>
								</Form.Group>
								<Form.Group as={Col} controlId="istraining">
									<Form.Check
										type="checkbox"
										label="Training"
										checked={this.state.istraining}
										onChange={this.onChange}
										name="istraining"
									/>
								</Form.Group>
							</Form.Row>
							<Button onClick={this.createVideo}>add video</Button>
							<br />
						</Form>
					</div>
				</Collapse>
			</Container>
		);
	}
}

export default CreateVidComponent;
