import React from "react";
import { Route, Switch, withRouter, Redirect, Link } from "react-router-dom";

import {
	Container,
	Row,
	Col,
	Image,
	Button,
	InputGroup,
	FormControl
} from "react-bootstrap";

import Header from "../elements/header";
import CategorySelectElement from "../elements/categoryselect";
import VideosTemplate from "./videos/videos";
import VideoTemplate from "./videos/video";
import PostsTemplate from "./posts/posts";
import PostTemplate from "./posts/post";
import Notfound from "./notfound";

import videoicon from "../video_1541069.png";
import peopleicon from "../FTF_people-icon.png";

import "./maintemplate.css";

class MainTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			viewall_button_txt: "VIEW ALL VIDEOS",
			viewall_button_href: "/" + this.props.match.params.section + "/videos",
			searchQuery: props.location.searchQuery
		};
	}

	static getDerivedStateFromProps(props, state) {
		let section = props.match.params.section;
		let view = props.match.params.view;
		if (section === "training") {
			if (view === "videos" || view === "video") {
				state.viewall_button_txt = "VIEW ALL TRAINING VIDEOS";
				state.viewall_button_href = "/training/videos";
			} else {
				state.viewall_button_txt = "VIEW ALL QUICK NOTES";
				state.viewall_button_href = "/training/fromthefield";
			}
		} else if (section === "admin") {
			state.viewall_button_txt = "MANAGE ALL VIDEOS";
			state.viewall_button_href = "/admin/videos";
		} else {
			state.viewall_button_txt = "VIEW ALL VIDEOS";
			state.viewall_button_href = "/c/videos";
		}
		return null;
	}

	componentDidMount() {}

	handleSearchboxChange = event => {
		this.setState({ searchQuery: event.target.value });
	};

	render() {
		let section = this.props.match.params.section;
		let view = this.props.match.params.view
			? this.props.match.params.view
			: "admin";
		let userscope = this.props.userscope;
		let useremail = this.props.useremail;
		let iconsrc = videoicon;
		let viewname = view.charAt(0).toUpperCase() + view.slice(1);
		if (viewname === "Fromthefield") viewname = "Quick Notes";
		let sectionname = section
			? section.charAt(0).toUpperCase() + section.slice(1)
			: "";
		if (sectionname === "C") sectionname = "Customer-Facing";

		if (view === "fromthefield" || view === "post") {
			iconsrc = peopleicon;
		}
		return (
			<div id="videos-template-wrapper">
				<Header
					userscope={userscope}
					section={section}
					view={view}
					useremail={useremail}
				/>
				<Container fluid>
					<Row className="search-bar" id={section + "-search-bar"}>
						<Container>
							<Row>
								<Col sm={6} md={6} lg={4}>
									<Image id="videoicon" src={iconsrc} alt="videos" />
									<Button
										className="viewall-button"
										variant="success"
										onClick={() => {
											this.setState({ searchQuery: "" });
											this.props.history.push({
												pathname: this.state.viewall_button_href,
												searchQuery: ""
											});
										}}
									>
										{this.state.viewall_button_txt}
									</Button>
								</Col>

								<Col xs={12} sm={6} md={6} lg={4}>
									{(view === "videos" || view === "fromthefield") && (
										<InputGroup className="searchbox">
											<FormControl
												placeholder="Type Keyword or Product # Here"
												aria-label="searchbox"
												aria-describedby="basic-addon2"
												value={
													this.state.searchQuery ? this.state.searchQuery : ""
												}
												onChange={this.handleSearchboxChange}
											/>
										</InputGroup>
									)}
								</Col>
								<Col lg={4}>
									<CategorySelectElement section={section} view={view} />
								</Col>
							</Row>
						</Container>
					</Row>
					<Row style={{ backgroundColor: "rgb(228, 228, 228)" }}>
						<Container>
							<Row>
								<Col>
									You are here: &nbsp;
									<Link to="/">Home</Link>
									&rarr; {sectionname}
									&rarr; {viewname}
									<Switch>
										<Route
											exact
											path={["/training/post/:id"]}
											render={() => this.props.match.params.id}
										/>
									</Switch>
								</Col>
							</Row>
						</Container>
					</Row>
				</Container>
				<section id="maincontent">
					<Switch>
						<Route
							exact
							path={["/admin/videos", "/admin/videos/:cat"]}
							render={() =>
								userscope !== "admin" ? (
									<Redirect to="/" />
								) : (
									<VideosTemplate
										searchQuery={this.state.searchQuery}
										section={section}
										userscope={userscope}
									/>
								)
							}
						/>
						<Route
							exact
							path={["/training/videos/:cat", "/training/videos"]}
							render={() =>
								userscope !== "training" && userscope !== "admin" ? (
									<Redirect to="/" />
								) : (
									<VideosTemplate
										searchQuery={this.state.searchQuery}
										section={section}
										userscope={userscope}
									/>
								)
							}
						/>
						<Route
							exact
							path={["/training/drumbeat/:cat", "/training/drumbeat"]}
							render={() =>
								userscope !== "training" && userscope !== "admin" ? (
									<Redirect to="/" />
								) : (
									<PostsTemplate
										searchQuery={this.state.searchQuery}
										section={section}
										userscope={userscope}
									/>
								)
							}
						/>
						<Route
							exact
							path={["/admin/fromthefield", "/admin/fromthefield/:cat"]}
							render={() =>
								userscope !== "admin" ? (
									<Redirect to="/" />
								) : (
									<PostsTemplate
										searchQuery={this.state.searchQuery}
										section={section}
										userscope={userscope}
									/>
								)
							}
						/>
						<Route
							exact
							path={["/training/fromthefield/:cat", "/training/fromthefield"]}
							render={() =>
								userscope !== "training" && userscope !== "admin" ? (
									<Redirect to="/" />
								) : (
									<PostsTemplate
										searchQuery={this.state.searchQuery}
										section={section}
										userscope={userscope}
									/>
								)
							}
						/>
						<Route
							path={["/training/post/:id", "/admin/post/:id"]}
							component={PostTemplate}
						/>
						<Route
							exact
							path={["/c/videos", "/c/videos/:cat"]}
							render={() => (
								<VideosTemplate
									searchQuery={this.state.searchQuery}
									section={section}
									userscope={userscope}
								/>
							)}
						/>
						<Route
							path={["/c/video/:id", "/training/video/:id", "/admin/video/:id"]}
							component={VideoTemplate}
						/>
						<Route component={Notfound} />
					</Switch>
				</section>
			</div>
		);
	}
}
export default withRouter(MainTemplate);
