/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getVideo = `query GetVideo($id: ID!) {
  getVideo(id: $id) {
    id
    title
    iscustomer
    istraining
    url
    sources {
      items {
        id
        url
        type
        display
      }
      nextToken
    }
    products {
      id
      name
      url
      videos {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
      description
      linked {
        nextToken
      }
    }
    taggedKeywords {
      id
      name
      inVideos {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
    }
    keywordstring
    categories {
      id
      name
      linked {
        nextToken
      }
      hasVideos {
        nextToken
      }
    }
    inCategory {
      id
      name
      linked {
        nextToken
      }
      hasVideos {
        nextToken
      }
    }
    inPost {
      id
      type
      title
      subtitle
      shortDesc
      longDesc
      productLinks {
        nextToken
      }
      images {
        nextToken
      }
      videos {
        nextToken
      }
      contributor {
        id
        name
        level
      }
      keywordstring
      catLinks {
        nextToken
      }
      startPublishing
      endPublishing
    }
  }
}
`;
export const listVideos = `query ListVideos(
  $filter: ModelVideoFilterInput
  $limit: Int
  $nextToken: String
) {
  listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      iscustomer
      istraining
      url
      sources {
        nextToken
      }
      products {
        id
        name
        url
        description
      }
      taggedKeywords {
        id
        name
      }
      keywordstring
      categories {
        id
        name
      }
      inCategory {
        id
        name
      }
      inPost {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
    }
    nextToken
  }
}
`;
export const getImage = `query GetImage($id: ID!) {
  getImage(id: $id) {
    id
    title
    caption
    sources {
      items {
        id
        url
        type
        display
      }
      nextToken
    }
    inPost {
      id
      type
      title
      subtitle
      shortDesc
      longDesc
      productLinks {
        nextToken
      }
      images {
        nextToken
      }
      videos {
        nextToken
      }
      contributor {
        id
        name
        level
      }
      keywordstring
      catLinks {
        nextToken
      }
      startPublishing
      endPublishing
    }
  }
}
`;
export const listImages = `query ListImages(
  $filter: ModelImageFilterInput
  $limit: Int
  $nextToken: String
) {
  listImages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      title
      caption
      sources {
        nextToken
      }
      inPost {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
    }
    nextToken
  }
}
`;
export const getPost = `query GetPost($id: ID!) {
  getPost(id: $id) {
    id
    type
    title
    subtitle
    shortDesc
    longDesc
    productLinks {
      items {
        id
      }
      nextToken
    }
    images {
      items {
        id
        title
        caption
      }
      nextToken
    }
    videos {
      items {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
      nextToken
    }
    contributor {
      id
      name
      level
      posts {
        nextToken
      }
    }
    keywordstring
    catLinks {
      items {
        id
      }
      nextToken
    }
    startPublishing
    endPublishing
  }
}
`;
export const listPosts = `query ListPosts(
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      title
      subtitle
      shortDesc
      longDesc
      productLinks {
        nextToken
      }
      images {
        nextToken
      }
      videos {
        nextToken
      }
      contributor {
        id
        name
        level
      }
      keywordstring
      catLinks {
        nextToken
      }
      startPublishing
      endPublishing
    }
    nextToken
  }
}
`;
export const getContributor = `query GetContributor($id: ID!) {
  getContributor(id: $id) {
    id
    name
    level
    posts {
      items {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
      nextToken
    }
  }
}
`;
export const listContributors = `query ListContributors(
  $filter: ModelContributorFilterInput
  $limit: Int
  $nextToken: String
) {
  listContributors(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      level
      posts {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getProduct = `query GetProduct($id: ID!) {
  getProduct(id: $id) {
    id
    name
    url
    videos {
      id
      title
      iscustomer
      istraining
      url
      sources {
        nextToken
      }
      products {
        id
        name
        url
        description
      }
      taggedKeywords {
        id
        name
      }
      keywordstring
      categories {
        id
        name
      }
      inCategory {
        id
        name
      }
      inPost {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
    }
    description
    linked {
      items {
        id
      }
      nextToken
    }
  }
}
`;
export const listProducts = `query ListProducts(
  $filter: ModelProductFilterInput
  $limit: Int
  $nextToken: String
) {
  listProducts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      url
      videos {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
      description
      linked {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getCategory = `query GetCategory($id: ID!) {
  getCategory(id: $id) {
    id
    name
    linked {
      items {
        id
      }
      nextToken
    }
    hasVideos {
      items {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
      nextToken
    }
  }
}
`;
export const listCategorys = `query ListCategorys(
  $filter: ModelCategoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      linked {
        nextToken
      }
      hasVideos {
        nextToken
      }
    }
    nextToken
  }
}
`;
export const getKeyword = `query GetKeyword($id: ID!) {
  getKeyword(id: $id) {
    id
    name
    inVideos {
      id
      title
      iscustomer
      istraining
      url
      sources {
        nextToken
      }
      products {
        id
        name
        url
        description
      }
      taggedKeywords {
        id
        name
      }
      keywordstring
      categories {
        id
        name
      }
      inCategory {
        id
        name
      }
      inPost {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
    }
  }
}
`;
export const listKeywords = `query ListKeywords(
  $filter: ModelKeywordFilterInput
  $limit: Int
  $nextToken: String
) {
  listKeywords(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      inVideos {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
    }
    nextToken
  }
}
`;
export const getSrc = `query GetSrc($id: ID!) {
  getSrc(id: $id) {
    id
    url
    s3object {
      bucket
      region
      key
    }
    type
    display
    video {
      id
      title
      iscustomer
      istraining
      url
      sources {
        nextToken
      }
      products {
        id
        name
        url
        description
      }
      taggedKeywords {
        id
        name
      }
      keywordstring
      categories {
        id
        name
      }
      inCategory {
        id
        name
      }
      inPost {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
    }
    image {
      id
      title
      caption
      sources {
        nextToken
      }
      inPost {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
    }
  }
}
`;
export const listSrcs = `query ListSrcs($filter: ModelSrcFilterInput, $limit: Int, $nextToken: String) {
  listSrcs(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      url
      s3object {
        bucket
        region
        key
      }
      type
      display
      video {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
      image {
        id
        title
        caption
      }
    }
    nextToken
  }
}
`;
export const getCatLink = `query GetCatLink($id: ID!) {
  getCatLink(id: $id) {
    id
    post {
      id
      type
      title
      subtitle
      shortDesc
      longDesc
      productLinks {
        nextToken
      }
      images {
        nextToken
      }
      videos {
        nextToken
      }
      contributor {
        id
        name
        level
      }
      keywordstring
      catLinks {
        nextToken
      }
      startPublishing
      endPublishing
    }
    category {
      id
      name
      linked {
        nextToken
      }
      hasVideos {
        nextToken
      }
    }
  }
}
`;
export const listCatLinks = `query ListCatLinks(
  $filter: ModelCatLinkFilterInput
  $limit: Int
  $nextToken: String
) {
  listCatLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      post {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
      category {
        id
        name
      }
    }
    nextToken
  }
}
`;
export const getProductLink = `query GetProductLink($id: ID!) {
  getProductLink(id: $id) {
    id
    post {
      id
      type
      title
      subtitle
      shortDesc
      longDesc
      productLinks {
        nextToken
      }
      images {
        nextToken
      }
      videos {
        nextToken
      }
      contributor {
        id
        name
        level
      }
      keywordstring
      catLinks {
        nextToken
      }
      startPublishing
      endPublishing
    }
    product {
      id
      name
      url
      videos {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
      description
      linked {
        nextToken
      }
    }
  }
}
`;
export const listProductLinks = `query ListProductLinks(
  $filter: ModelProductLinkFilterInput
  $limit: Int
  $nextToken: String
) {
  listProductLinks(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      post {
        id
        type
        title
        subtitle
        shortDesc
        longDesc
        keywordstring
        startPublishing
        endPublishing
      }
      product {
        id
        name
        url
        description
      }
    }
    nextToken
  }
}
`;
