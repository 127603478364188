import React from "react";
import { withRouter } from "react-router-dom";

import matchSorter from "match-sorter";
import ReactTable from "react-table";

import { Container, Row, Col } from "react-bootstrap";
import "./vidstable.css";

class VidsTable extends React.Component {
	render() {
		return (
			<Container fluid className="wht-fluid-container">
				<Container>
					<Row>
						<Col>
							{this.props.category ? "Filered By:  " : ""} {this.props.category}
							<ReactTable
								className="-striped -highlight"
								data={this.props.data}
								columns={this.props.columns}
								loading={this.props.loading}
								loadingText={
									<div
										className="table-loading-div spinner-border text-success"
										role="status"
									>
										<span className="sr-only">Loading...</span>
									</div>
								}
								noDataText="No videos found to match your search"
								showPagination={true}
								showPaginationTop={true}
								showPaginationBottom={true}
								defaultFilterMethod={(filter, rows) =>
									matchSorter(rows, filter.value, {
										keys: ["title", "categories", "id", "keywordstring"]
									})
								}
								filterAll
								filtered={[
									{
										// the current filters model
										id: "title",
										value: this.props.searchQuery
									},
									{
										id: "categories",
										value: this.props.category
									},
									{
										id: "keywordstring",
										value: this.props.keywordstring
									}
								]}
								getTdProps={(state, rowInfo) => {
									if (rowInfo) {
										return {
											onClick: () =>
												this.props.history.push(
													"/" +
														this.props.section +
														"/video/" +
														rowInfo.original.id
												)
										};
									} else {
										return {
											onClick: () =>
												this.props.history.push(
													"/" + this.props.section + "/videos/"
												)
										};
									}
								}}
							/>
						</Col>
					</Row>
				</Container>
			</Container>
		);
	}
}

export default withRouter(VidsTable);
