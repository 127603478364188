import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";
import { Auth, Hub } from "aws-amplify";
import Header from "./elements/header";
import SignInForm from "./components/auth/signinform";

import MainTemplate from "./templates/main";
import EmployeeDashboardTemplate from "./templates/employeedashboard";
import CustomerDashboardTemplate from "./templates/customerdashboard";
import HiveTemplate from "./templates/hive";
import Notfound from "./templates/notfound";

import "./App.css";

class AppTemplate extends React.Component {
	// 	constructor(props) {
	// 		super(props);
	// 		let usergroups = this.props.authData.signInUserSession.idToken.payload[
	// 			"cognito:groups"
	// 		]
	// 			? this.props.authData.signInUserSession.idToken.payload["cognito:groups"]
	// 			: [];
	// 		let userIsAdmin = usergroups.includes("Admins");
	// 		const useremail = this.props.authData.attributes.email;
	// 		const ulabsdomain = "@unitedlabsinc.com";
	// 		if (userIsAdmin) {
	// 			this.state = { userscope: "admin" };
	// 		} else if (useremail.indexOf(ulabsdomain) > -1) {
	// 			this.state = { userscope: "training" };
	// 		} else {
	// 			this.state = { userscope: "c" };
	// 		}
	//	}
	state = { user: null, customState: null };

	componentDidMount() {
		Hub.listen("auth", ({ payload: { event, data } }) => {
			switch (event) {
			case "signIn":
				this.setState({ user: data });
				break;
			case "signOut":
				this.setState({ user: null });
				break;
			case "customOAuthState":
				this.setState({ customState: data });
			}
		});

		Auth.currentAuthenticatedUser()
			.then(user => this.setState({ user }))
			.catch(() => console.log("Not signed in"));
	}
	render() {
		const { user } = this.state;
		let userscope = "c";
		if (user && user.attributes) {
			userscope =
				user.attributes.email.indexOf("@unitedlabsinc.com") > -1
					? "training"
					: "c";
		}
		// 	const ulabsdomain = "@unitedlabsinc.com";
		// 	if (userIsAdmin) {
		// 		userscope = "admin";
		// 	} else if (useremail.indexOf(ulabsdomain) > -1) {
		// 		userscope = "training";
		// 	}
		// }
		return (
			<>
				{user && user.attributes ? (
					<Router>
						<Switch>
							<Route
								exact
								path={["/hive", "/sales-i", "/salesi"]}
								component={HiveTemplate}
							/>
							<Route
								exact
								strict
								path="/"
								render={() =>
									userscope === "training" || userscope === "admin" ? (
										<EmployeeDashboardTemplate
											useremail={user.attributes.email}
											userscope={userscope}
										/>
									) : (
										<CustomerDashboardTemplate
											useremail={user.attributes.email}
											userscope={userscope}
										/>
									)
								}
							/>
							<Route
								path={["/:section/:view", "/:section"]}
								render={() => (
									<MainTemplate
										userscope={userscope}
										useremail={user.attributes.email}
									/>
								)}
							/>
							<Route component={Notfound} />
						</Switch>
					</Router>
				) : (
					<Router>
						<Switch>
							<Route
								exact
								path={["/hive", "/sales-i", "/salesi"]}
								component={HiveTemplate}
							/>
							<Route
								exact
								path="/"
								render={() =>(
									<div>
										<Header view="customerdashboard" />
										{!user && <SignInForm />}
									</div>
								)}
							/>


							<Route component={Notfound} />
						</Switch>
					</Router>

				)}
			</>
		);
	}
}

export default AppTemplate;
