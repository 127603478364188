import React from "react";
import { Button, InputGroup, Form, FormControl } from "react-bootstrap";
import { withRouter } from "react-router-dom";

class SearchBox extends React.Component {
	constructor(props) {
		super(props);
		this.state = { query: "" };

		this.handleChange = this.handleChange.bind(this);
		this.handleSubmit = this.handleSubmit.bind(this);
	}

	handleChange(event) {
		this.setState({ query: event.target.value });
	}

	handleSubmit(event) {
		console.log(event);
		//event.preventDefault();
		this.props.history.push({
			pathname: this.props.section + "/" + this.props.view,
			searchQuery: this.state.query
		});
	}

	render() {
		return (
			<Form.Group>
				<InputGroup className="searchbox">
					<FormControl
						placeholder="Type Keyword or Product #"
						aria-label="query"
						name="query"
						ref={this.props.searchQuery}
						onChange={this.handleChange}
						onKeyPress={event => {
							if (event.key === "Enter") {
								this.handleSubmit();
							}
						}}
					/>
					<InputGroup.Append>
						<Button
							id="search-vids-button"
							className="search-button"
							variant="light"
							onClick={this.handleSubmit}
						>
							Search
						</Button>
					</InputGroup.Append>
				</InputGroup>
			</Form.Group>
		);
	}
}

export default withRouter(SearchBox);
