import React from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Image } from "react-bootstrap";

import UserPanelComponent from "../components/auth/userpanel";

import logo from "../logo.png";

function HeaderTextCol(props) {
	let viewname = props.view.charAt(0).toUpperCase() + props.view.slice(1);
	if (viewname === "Fromthefield") viewname = "Quick Notes";
	let sectionname = props.section
		? props.section.charAt(0).toUpperCase() + props.section.slice(1)
		: "";
	if (sectionname === "C") sectionname = "Customer-Facing";
	if (props.userscope === "training" || props.userscope === "admin") {
		if (
			(viewname === "Videos" || viewname === "Video") &&
			props.section === "c"
		) {
			return (
				<Col sm={6} id="headertxt_col">
					<Row>
						<Col>You are Viewing Customer Facing Videos</Col>
						<Col>
							<Link to="/training/videos">
								Switch To View Representive-Only Videos
							</Link>
						</Col>
					</Row>
				</Col>
			);
		} else if (
			(viewname === "Videos" ||
				viewname === "Quick Notes" ||
				viewname === "Video") &&
			props.section === "training"
		) {
			return (
				<Col sm={6} id="headertxt_col">
					<Row>
						<Col sm={6}>
							<Link to="/c/videos">Switch To View Customer Facing Videos</Link>
						</Col>
						<Col sm={6}>You are Viewing Representive-Only Content</Col>
					</Row>
				</Col>
			);
		} else if (viewname === "Videos" && props.section === "admin") {
			return (
				<Col sm={6} id="headertxt_col">
					You are Viewing All Videos - For Admins Only
				</Col>
			);
		} else {
			return (
				<Col sm={6} id="headertxt_col">
					WELCOME! We&apos;re here to help! <br />
					Call 1-800-323-2594 if you have any questions
				</Col>
			);
		}
	} else {
		return (
			<Col sm={6} id="headertxt_col">
				WELCOME! We&apos;re here to help! <br />
				Call 1-800-323-2594 to speak to a United Representative
			</Col>
		);
	}
}

const Header = props => {
	return (
		<section id="header">
			<Container fluid>
				<Row id="header_row">
					<Col xs={12} sm={3} id="logo_col">
						<Link to={"/"}>
							<Image fluid id="logoimg" src={logo} alt="Logo" />
						</Link>
					</Col>
					<HeaderTextCol
						userscope={props.userscope}
						view={props.view}
						section={props.section}
					/>
					<Col xs={6} sm={3} id="userstatus_col">
						<UserPanelComponent useremail={props.useremail} />
					</Col>
				</Row>
			</Container>
		</section>
	);
};

export default Header;
