import React from "react";
import { Route, withRouter } from "react-router-dom";
import { DropdownButton, Dropdown } from "react-bootstrap";
import "./categoryselect.css";
const CategorySelectElement = props => {
	let view = props.view === "video" ? "videos" : props.view;
	if (view === "post") view = "fromthefield";
	const cats = [
		{
			id: "aromatherapy",
			name: "Aromatherapy"
		},
		{
			id: "carpet_care",
			name: "Carpet Care"
		},
		{
			id: "coatings",
			name: "Coatings"
		},
		{
			id: "degreasers",
			name: "Degreasers"
		},
		{
			id: "deodorants",
			name: "Deodorants"
		},
		{
			id: "disinfectants",
			name: "Disinfectants"
		},
		{
			id: "drain_maintenance",
			name: "Drain Maintenance"
		},
		{
			id: "facility_maintenance",
			name: "Facility Maintenance"
		},
		{
			id: "hvac",
			name: "HVAC"
		},
		{
			id: "housekeeping",
			name: "Housekeeping"
		},
		{
			id: "industrial_specialties",
			name: "Industrial Specialties"
		},
		{
			id: "insecticides",
			name: "Insecticides"
		},
		{
			id: "lubricants",
			name: "Lubricants"
		},
		{
			id: "personal_care",
			name: "Personal Care"
		},
		{
			id: "wastewater",
			name: "Wastewater"
		}
	];
	return (
		<div id="category-dropdown-wrapper">
			<Route
				render={withRouter(({ history }) => (
					<DropdownButton
						id="category-dropdown-button"
						title="Search By Category"
						variant="light"
					>
						{cats.map(cat => (
							<Dropdown.Item
								key={cat.id.toString()}
								onSelect={() => {
									history.push("/" + props.section + "/" + view + "/" + cat.id);
								}}
							>
								{cat.name}
							</Dropdown.Item>
						))}
					</DropdownButton>
				))}
			/>
		</div>
	);
};

export default withRouter(CategorySelectElement);
