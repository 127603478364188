import React from "react";
import { withRouter } from "react-router-dom";

import matchSorter from "match-sorter";
import { API, graphqlOperation } from "aws-amplify";

import PostsTable from "../../components/poststable";

import "./posts.css";

const listPostsQuery = `query ListPosts(
  $filter: ModelPostFilterInput
  $limit: Int
  $nextToken: String
) {
  listPosts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      title
      subtitle
      shortDesc
      longDesc
      productLinks {
        items {
          product {
            id
            name
            url
            description
          }
        }
      }
      catLinks {
        items {
          category {
            id
            name
          }
        }
      }
      images {
        nextToken
      }
      videos {
        nextToken
      }
      contributor {
        id
        name
        level
      }
      keywordstring
      catLinks {
        nextToken
      }
      startPublishing
      endPublishing
    }
    nextToken
  }
}
`;
class PostsTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			listPosts: {
				items: [
					{
						id: "",
						title: "",
						shortDesc: "",
						keywordstring: "",
						contributorname: "",
						contributor: {
							level: 0
						},
						productLinks: {
							items: []
						},
						catLinks: {
							items: []
						},
						startPublishing: "",
						endPublishing: ""
					}
				]
			},
			tableloading: true,
			section: ""
		};
	}
	componentDidMount() {
		this.getPosts();
	}
	static getDerivedStateFromProps(nextProps, prevState) {
		if (nextProps.section !== prevState.section) {
			return { section: nextProps.section };
		} else return null;
	}

	componentDidUpdate(prevProps) {
		if (prevProps.section !== this.props.section) {
			this.getPosts();
		}
	}

	getPosts = async () => {
		this.setState({ tableloading: true });
		let filter = { endPublishing: { gt: (Date.now() / 1000) | 0 } };
		const allPosts = await API.graphql(
			graphqlOperation(listPostsQuery, { limit: 500, filter: filter })
		);
		this.setState(allPosts.data);
		this.setState({ tableloading: false });
	};

	render() {
		const tabledata = this.state.listPosts.items;
		const tablecolumns = [
			{
				Header: "Products Featured",
				id: "products",
				accessor: function accessor(d) {
					if (d.productLinks.items === undefined) {
						return " ";
					} else {
						return d.productLinks.items.map(function(prodlink) {
							return prodlink.product.id + " ";
						});
					}
				},
				maxWidth: 100
			},
			{
				Header: "Title",
				accessor: "title",
				FilterMethod: (filter, rows) =>
					matchSorter(rows, filter.value, { keys: ["title", "id"] }),
				filterAll: true,
				maxWidth: 300
			},
			{
				Header: "Description",
				accessor: "shortDesc"
			},
			{
				Header: "Contributor",
				id: "contributor",
				accessor: d => d.contributor,
				maxWidth: 100,
				Cell: row => (
					<span>
						<img
							id="user_badge_img"
							src={
								process.env.PUBLIC_URL +
								"/img/badge_" +
								row.value.level +
								".svg"
							}
							alt="User Badge"
						/>
						{row.value.name}
					</span>
				)
			},
			{
				id: "startPublishing",
				Header: "Date",
				maxWidth: 100,
				accessor: function accessor(d) {
					return new Date(d.startPublishing * 1000).toLocaleDateString("en-US");
				}
			},
			{
				Header: "id",
				accessor: "id",
				filterAll: true,
				headerStyle: { display: "none" },
				style: { display: "none" }
			},
			{
				id: "keywordstring",
				Header: "Keywordstring",
				accessor: "keywordstring",
				filterAll: true,
				headerStyle: { display: "none" },
				style: { display: "none" }
			},
			{
				id: "categories",
				Header: "Catergories",
				accessor: function accessor(d) {
					if (d.catLinks.items === undefined) {
						return " ";
					} else {
						return d.catLinks.items.map(function(catlink) {
							return catlink.category.name + " ";
						});
					}
				},
				headerStyle: { display: "none" },
				style: { display: "none" },
				filterAll: true
			}
		];

		return (
			<div id="videos-template-wrapper">
				<section id="maincontent">
					<PostsTable
						data={tabledata}
						columns={tablecolumns}
						searchQuery={this.props.searchQuery}
						category={this.props.match.params.cat}
						loading={this.state.tableloading}
						section={this.props.section}
						userscope={this.props.userscope}
						react-multi-select
					/>
				</section>
			</div>
		);
	}
}
export default withRouter(PostsTemplate);
