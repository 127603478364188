import React from "react";
import { API, graphqlOperation } from "aws-amplify";
import { Container, Row, Col } from "react-bootstrap";
import PlayerComponent from "../../components/player";
import "./video.css";
const getVideoQuery = `query GetVideo($id: ID!) {
  getVideo(id: $id) {
    id
    title
    iscustomer
    istraining
    url
    sources {
      items {
        id
        url
        type
        display
      }
      nextToken
    }
    products {
      id
      name
      url
      videos {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
      description
      linked {
        nextToken
      }
    }
    taggedKeywords {
      id
      name
      inVideos {
        id
        title
        iscustomer
        istraining
        url
        keywordstring
      }
    }
    keywordstring
    categories {
      id
      name
      linked {
        nextToken
      }
      hasVideos {
        nextToken
      }
    }
    inCategory {
      id
      name
      linked {
        nextToken
      }
      hasVideos {
        nextToken
      }
    }
    inPost {
      id
      type
      title
      subtitle
      shortDesc
      longDesc
      productLinks {
        nextToken
      }
      images {
        nextToken
      }
      videos {
        nextToken
      }
      contributor {
        id
        name
        level
      }
      keywordstring
      catLinks {
        nextToken
      }
      startPublishing
      endPublishing
    }
  }
}
`;
class VideoTemplate extends React.Component {
	constructor(props) {
		super(props);
		this.state = { hasProducts: false };
	}

	componentDidMount() {
		this.getVideo();
	}
	getVideo = async () => {
		try {
			const vidrequest = {
				id: this.props.match.params.id
			};
			const vidresponse = await API.graphql(
				graphqlOperation(getVideoQuery, vidrequest)
			);
			this.setState(vidresponse.data.getVideo);
			let src1 = vidresponse.data.getVideo.sources.items[0];
			src1.src = src1.url;
			this.setState({
				src1: src1
			});
		} catch (err) {
			console.log("error fetching video");
		}
	};

	render() {
		return (
			<section>
				<Container>
					<Row>
						<Col id="video_player_col" className="col-12 col-md-8">
							{this.state.src1 === undefined ? (
								<p></p>
							) : (
								<PlayerComponent source={this.state.src1} playing />
							)}
						</Col>
						<Col id="vid_info_col">
							<h1>{this.state.title}</h1>

							<ul>
								{this.state.products === undefined
									? " No products "
									: this.state.products.map(product => (
										<li key={product.id}>
											<a
												href={product.url}
												target="_blank"
												rel="noopener noreferrer"
											>
													United {product.id} {product.name}
											</a>
										</li>
									  ))}
							</ul>
						</Col>
					</Row>
				</Container>
			</section>
		);
	}
}
export default VideoTemplate;
