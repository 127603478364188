import React from "react";
import ReactPlayer from "react-player";
import { Modal, Button} from "react-bootstrap";
import * as Styles from "./hivetemplate.module.scss";

import logo from "../logo.png";
const si_logosrc = "https://assets.ulabs.info/hivepage/salesi_logo.png";
const pdficonsrc = "https://assets.ulabs.info/hivepage/pdficon.png";
const emailiconsrc = "https://assets.ulabs.info/hivepage/emailicon.png";
const helpiconsrc = "https://assets.ulabs.info/hivepage/helpicon.png";


// data
const cards = [
	{
		id: "sess1",
		title: "<b>SALES REP TRAINING SESSION #1<br /> From sales-i, with Bri</b><br />Searching for a Customer<br />Full Picture Enquiry<br />Scheduling a Call<br />Logging a Call Note",
		img: "https://assets.ulabs.info/hivepage/sess1_card_play.png",
		vidsrc: "https://assets.ulabs.info/hivepage/sales-i_training1a/sales-i_training1a-720p.mp4",
		color: "#E95800",
	},
	{
		id: "sess2",
		title: "<b>SALES REP TRAINING SESSION #2<br /> From sales-i, with Bri</b><br />Adding Prospects<br />Call Notes<br />Call Scheduling",
		img: "https://assets.ulabs.info/hivepage/sess2_card_play.png",
		vidsrc:
      "https://assets.ulabs.info/hivepage/sales-i_training1b/sales-i_training1b-720p.mp4",
		color: "#1099A8",
	},
	{
		id: "sess3",
		title: "<b>SALES REP TRAINING SESSION #3<br /> From sales-i, with Bri</b><br />Mapping Feature<br />Going on Autopilot<br />Run a Snapshot<br />Variance Enquiry",
		img: "https://assets.ulabs.info/hivepage/sess3_card_play.png",
		vidsrc: "https://assets.ulabs.info/hivepage/sales-i_training1c/sales-i_training1c-720p.mp4",
		color: "#BC027F",
	},
	{
		id: "findaccts",
		title: "HOW TO FIND MY ACCOUNTS",
		img: "https://assets.ulabs.info/hivepage/findacc_card.png",
		vidsrc: false,
		pdfurl:
      "https://assets.ulabs.info/hivepage/Find-Accounts-In-sales-i.pdf",
		pdftitle: "Download the PDF ",
		color: "#0D96F2",
	},
	{
		id: "viewsales",
		title: "VIEW CUSTOMER SALES INFO",
		img: "https://assets.ulabs.info/hivepage/viewsales_card.png",
		vidsrc: false,
		pdfurl: "https://assets.ulabs.info/hivepage/View-Customer-Account-Sales.pdf",
		pdftitle: "Download the PDF ",
		color: "#8EB814",
	},
	{
		id: "calls1",
		title: "GETTING STARTED WITH MY CALLS",
		img: "https://assets.ulabs.info/hivepage/calls1_card.png",
		vidsrc: "https://assets.ulabs.info/hivepage/Getting-Started-MyCalls/Getting-Started-MyCalls-web.mp4",
		pdfurl: "https://assets.ulabs.info/hivepage/Getting-Started-MyCalls/Getting-started-MyCalls-Apple.pdf",
		pdftitle: "Download the PDF ",
		color: "#663399",
	},
	{
		id: "addprospect",
		title: "ADDING A PROSPECT",
		img: "https://assets.ulabs.info/hivepage/addprospect_card.png",
		vidsrc: false,
		pdfurl: "https://assets.ulabs.info/hivepage/add-prospect-Apple.pdf",
		pdftitle: "Download the PDF ",
		color: "#663399",
	},
	{
		id: "generatecalls",
		title: "HOW TO GENERATE CALLS",
		img: "https://assets.ulabs.info/hivepage/generatecalls_card.png",
		vidsrc: false,
		pdfurl: "https://assets.ulabs.info/hivepage/generate-calls-APPLE-toni.pdf",
		pdftitle: "Download the PDF ",
		color: "#663399",
	},

	{
		id: "addappts",
		title: "HOW TO ADD FUTURE APPOINTMENTS",
		img: "https://assets.ulabs.info/hivepage/addappts_card.png",
		vidsrc: false,
		pdfurl: "https://assets.ulabs.info/hivepage/add-future-appointments-Apple.pdf",
		pdftitle: "Download the PDF ",
		color: "#663399",
	},

	{
		id: "maps",
		title: "USE THE MAPS FEATURE",
		img: "https://assets.ulabs.info/hivepage/maps_card.png",
		vidsrc: false,
		pdfurl: "https://assets.ulabs.info/hivepage/How-to-use-the-Maps-feature.pdf",
		pdftitle: "Download the PDF ",
		color: "#663399",
	},

	{
		id: "snapshot",
		title: "RUN A QUICK CUSTOMER SNAPSHOT",
		img: "https://assets.ulabs.info/hivepage/snapshot_card.png",
		vidsrc: false,
		pdfurl: "https://assets.ulabs.info/hivepage/Run-QuickSnapshot.pdf",
		pdftitle: "Download the PDF ",
		color: "#663399",
	},

	{
		id: "autopilot",
		title: "USING AUTOPILOT",
		img: "https://assets.ulabs.info/hivepage/autopilot_card.png",
		vidsrc: false,
		pdfurl: "https://assets.ulabs.info/hivepage/Autopilot-How-to-Guide.pdf",
		pdftitle: "Download the PDF ",
		color: "#663399",
	},

	{
		id: "routine",
		title: "A PERFECT CALL ROUTINE FLOW",
		img: "https://assets.ulabs.info/hivepage/routine_card.png",
		vidsrc: false,
		pdfurl: "https://assets.ulabs.info/hivepage/sales-i-guide-to-perfect-callroutine-iOS.pdf",
		pdftitle: "Download the PDF ",
		color: "#663399",
	},
];
const CardComponent = (props) => {
	const { index, id, title, vidsrc, img, pdfurl } = props;

	const [show, setShow] = React.useState(false);
	const handleClose = () => {setPlaying(false);setShow(false);};
	const handleShow = () => {setPlaying(true);setShow(true);};
	const [playing, setPlaying] = React.useState(false);

	return (
		<div>
			{vidsrc && (
				<>
					<button className={Styles.link} onClick={handleShow}>
						<img src={img} alt={title} className={Styles.cardImg} />
					</button>
					<button
						className={Styles.link}
						onClick={handleShow}
						role="link"
						tabIndex={index}
					>
						<div dangerouslySetInnerHTML={{__html:title}} />
					</button>
					<Modal
						show={show}
						onHide={handleClose}
						size="lg"
						key={id}
					>
						<Modal.Header closeButton ><div dangerouslySetInnerHTML={{__html:title}} /></Modal.Header>
						<Modal.Body>
							<ReactPlayer url={vidsrc} width="100%" controls playing={playing} />
						</Modal.Body>
						<Modal.Footer>
							<Button color="secondary" onClick={handleClose}>
              Close
							</Button>
						</Modal.Footer>
					</Modal>
				</>
			)}
			{!vidsrc &&
	(
		<>
			<button className={Styles.link}>

				<a
					href={pdfurl}
					target="_blank"
					rel="noreferrer"
				>
					<img src={img} alt={title} className={Styles.cardImg} />
				</a>
			</button>
			<button
				className={Styles.link}
				role="link"
				tabIndex={index}
			>
				<a
					href={pdfurl}
					target="_blank"
					rel="noreferrer"
				>
					<div dangerouslySetInnerHTML={{__html:title}} />
				</a>
			</button>
		</>
	)}
		</div>
	);
};
const HiveTemplate = () => {
	return (<div className={Styles.page}>
		<div className={Styles.mainContainerDiv}>
			<div className={Styles.headerDiv}>
				<div className={Styles.headerLeftDiv}>
					<img src={logo} alt="united logo" className={Styles.logoImg} />
					<img src={si_logosrc} alt="sales-i logo" className={Styles.logoImg} style={{paddingTop:"15px"}}/>
				</div>
				<div className={Styles.headerRightDiv}>
					<h1>Welcome to The HIVE, powered by sales-i</h1>
					<p>
            sales-i is a powerful Customer Relationship Management (CRM) software sales tool helps you keep organized and helps you sell smarter. The Hive is a place where every individual contributes to their colonies. From scouting expeditions to foraging we bring important pieces of information from out in the wild back to the sanctuary of the HIVE. sales-i keeps that information organized and helps you  increase your sales effectiveness by giving insights into every customer, product and sale.
					</p>
				</div>
			</div>
			<div className={Styles.cardscontainer}>
				{cards.map((card, i) => (
					<div key={card.title} className={Styles.card}>
						<CardComponent
							id={card.id}
							title={card.title}
							vidsrc={card.vidsrc}
							pdfurl={card.pdfurl}
							img={card.img}
							index={i}
						/>
						{card.pdfurl && (
							<a href={`${card.pdfurl}`} target="_blank" rel="noreferrer">
								<img
									src={pdficonsrc}
									alt="pdf"
									style={{ maxWidth: "30px", padding: "5px" }}
								/>
								<span aria-label="PDF Icon">{card.pdftitle}</span>
							</a>
						)}
					</div>
				))}
			</div>
			<h1 className={Styles.helpHeading}>STILL NEED HELP?</h1>
			<div className={Styles.helpLinks}>
				<a
					href="mailto:tholder@unitedlabsinc.com"
					target="_blank"
					rel="noreferrer"
				>
					<img src={emailiconsrc} alt="email icon" />
					<span>eMail the helpdesk at:</span>
					<span
						style={{
							color: "#7f0aca",
							textDecoration: "underline",
							fontWeight: "bold",
						}}
					>
                THolder@unitedlabsinc.com
					</span>
				</a>
				<div>or</div>
				<a
					href="https://support.sales-i.com/en"
					target="_blank"
					rel="noreferrer"
				>
					<img src={helpiconsrc} alt="help icon" />
					<span>Visit the Knowledge HUB at:</span>
					<span
						style={{
							color: "#7f0aca",
							textDecoration: "underline",
							fontWeight: "bold",
						}}
					>
				support.sales-i.com
					</span>
				</a>
			</div>
		</div>
	</div>
	);
};
export default HiveTemplate;
