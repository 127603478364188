import "core-js/stable";
import "regenerator-runtime/runtime";
import Amplify from "aws-amplify";
//import awsExports from "./aws-exports";
import "whatwg-fetch";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter as Router } from "react-router-dom";
import AppTemplate from "./App";
import Footer from "./elements/footer";
import { Container } from "react-bootstrap";

import "./index.css";
import * as serviceWorker from "./serviceWorker";

// Amplify.configure();
const AwsConfig = {
	aws_project_region: "us-east-1",
	aws_cognito_identity_pool_id:
		"us-east-1:5113d55f-c014-4fe6-bfe1-3a99b7cb9066",
	aws_cognito_region: "us-east-1",
	aws_user_pools_id: "us-east-1_yVEkNrmeD",
	aws_user_pools_web_client_id: "4ok5s6kvrl4kcgef4lkg6sc2ur",
	aws_appsync_graphqlEndpoint:
		"https://mce7p3j5bje4deqfygimnzkwa4.appsync-api.us-east-1.amazonaws.com/graphql",
	aws_appsync_region: "us-east-1",
	aws_appsync_authenticationType: "AMAZON_COGNITO_USER_POOLS",
	aws_user_files_s3_bucket: "ulabs2651693f574564cb1ac8d151181495ed5-prod",
	aws_user_files_s3_bucket_region: "us-east-1",
	aws_mobile_analytics_app_id: "f4e0d4981ec048358dc3e3a241975018",
	aws_mobile_analytics_app_region: "us-east-1",
	oauth: {
		domain: "ulabs.auth.us-east-1.amazoncognito.com",
		scope: [
			"phone",
			"email",
			"openid",
			"profile",
			"aws.cognito.signin.user.admin"
		],
		redirectSignIn: "https://www.ulabs.info/",
		redirectSignOut: "https://www.ulabs.info/",
		responseType: "code"
	},
	federationTarget: "COGNITO_USER_AND_IDENTITY_POOLS"
};

Amplify.configure(AwsConfig);

const routing = (
	<Router>
		<Container fluid id="appcontainer">
			<link
				rel="stylesheet"
				href="https://maxcdn.bootstrapcdn.com/bootstrap/4.3.1/css/bootstrap.min.css"
				integrity="sha384-ggOyR0iXCbMQv3Xipma34MD+dH/1fQ784/j6cY/iJTQUOhcWr7x9JvoRxT2MZw1T"
				crossOrigin="anonymous"
			/>
			<AppTemplate />
			<Footer />
		</Container>
	</Router>
);

ReactDOM.render(routing, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
