import React from "react";
import ReactPlayer from "react-player";

class PlayerComponent extends React.Component {
	render() {
		return (
			<div onContextMenu={e => e.preventDefault()}>
				<ReactPlayer
					url={[
						{
							src: this.props.source.src,
							type: this.props.source.type
						}
					]}
					controls
					config={{
						file: {
							attributes: {
								onContextMenu: e => e.preventDefault(),
								controlsList: "nodownload"
							}
						}
					}}
					width=" "
					className="col-12"
					height="auto"
					playing={this.props.playing}
				/>
			</div>
		);
	}
}
export default PlayerComponent;
